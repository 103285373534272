import React, { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    CUSTOMER_PRODUCTS,
    useGetCustomerProductsPrices,
    useUpdateCustomerProductPrice
} from 'hooks/customer/products.hooks';
import {
    Avatar,
    Box,
    Chip,
    Grid,
    IconButton,
    InputAdornment,
    TableCell,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import PricesFilterBar from './PricesFilterBar';
import MasterTable from 'ui-component/master-table/MasterTable';
import Loader from 'ui-component/Loader';
import { HeadCell } from 'types';
import IItem from 'types/product_delivery';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { dateObjectToString, roundDecimals } from '../../../../fromKotlin/nkutils';
import { CurrencyShekel, Percentage } from 'tabler-icons-react';
import { copyClientPrice, updateAllPrice } from '../../../../api/customer/products_prices.api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import {
    getCustomers,
    getSpecificCustomer,
    selectActiveCustomers,
    selectCustomerById
} from '../../../../store/customers/customersSlice';
import AllProductPriceChangesDialog from '../../../../ui-component/mass-changes-dialogs/massPriceChangeDialogs';
import { useQueryClient } from 'react-query';
import {CheckCircleOutline, CopyAll} from '@mui/icons-material';
import DialogChooseAction from '../../../../ui-component/dialogs/SelectionDialog';
import Customer from '../../../../types/customer';
import { SNACKBAR_OPEN } from '../../../../store/actions';
import Swal from 'sweetalert2';
import NoNegative from '../../../../ui-component/textFieldNumber';
import { handleKeyDown } from '../../../../utils/tableAsKeyBorad';
import GenerativeLoader from "../../../../ui-component/GenerativeLoader";
import { getFile, pricesPdf } from "../../../../api/customer/files.api";
import { pdf } from "../../../../assets/images";
import AgTable, { ITableControls } from 'ui-component/ag-grid/Ag-Table';
import { numberCellRenderer } from 'ui-component/ag-grid/ag-table-utils';
import { IconInfoCircle } from '@tabler/icons';
import JSDB from "../../../../fromKotlin/nk";
import { IAgChip } from 'ui-component/ag-grid/components/Ag-Chip';
import basicPinnedControl from "../../../../ui-component/ag-grid/utils/basicPinnedTopRow";
import MyChip from "../../../../ui-component/extended/Chip";
import AllProductAvailableChangesDialog from "../../../../ui-component/mass-changes-dialogs/massAvailableChangeDialogs";

const columns: HeadCell[] = [
    {
        id: 'checkbox',
        numeric: false,
        label: '',
        align: 'center',
        type: 'string',
    },
    { id: 'id', numeric: false, label: 'מזהה', align: 'center', type: 'string' },
    { id: 'external_id', numeric: false, label: 'מזהה חיצוני', align: 'center',type:'numOrStr', hide: true },
    { id: 'name', numeric: false, label: 'שם מוצר', align: 'center', type: 'string' },
    { id: 'barcode', numeric: false, label: 'ברקוד', align: 'center', type: 'string' },
    { id: 'category', numeric: false, label: 'קטגוריה', align: 'center', type: 'string', hide: true, enableRowGroup: true },
    { id: 'group', numeric: false, label: 'קבוצה', align: 'center', type: 'string', hide: true },
    { id: 'unit', numeric: false, label: 'יחידות', align: 'center', type: 'string', hide: true },

    {
        id: 'totalPrice',
        numeric: true,
        label: 'מחיר',
        align: 'center',
        type: 'number',
        filterable: false,
        cellRenderer: ({ params }) => {
            return numberCellRenderer(params, "currency")
        },
        editable: true,
        pinnedRowProps: {
            action: "avg",
            format: "currency"
        },
        dataFormat: "currency"
    },
    {
        id: 'discount',
        numeric: true,
        label: 'הנחה',
        align: 'center',
        type: 'number',
        filterable: false,
        cellRenderer: ({ params }) => {
            return numberCellRenderer(params, "percentage")
        },
        editable: true,
        pinnedRowProps: {
            action: "avg",
            format: "currency"
        },
        dataFormat: "percent",
        valueLimit: {
            max: 100,
            min: 0
        }
    },
    {
        id: 'after',
        numeric: true,
        label: 'לאחר הנחה',
        align: 'center',
        type: 'number',
        pinnedRowProps: {
            action: "avg",
            format: "currency"
        },
        extractFunc: (row) => {
            return roundDecimals(row.totalPrice * (1 - row.discount / 100));
        },
        filterable: false,
        cellRenderer: ({ params }) => {
            return numberCellRenderer(params, "currency")
        },
        dataFormat: "currency"
    },
    {
        id: 'costPrice',
        numeric: true,
        label: 'מחיר עלות',
        align: 'center',
        hide: true,
        type: 'number',
        pinned: "left",
        filterable: false,
        cellRenderer: ({ params }) => {
            return numberCellRenderer(params, "currency")
        }
    },
    {
        id: 'available',
        numeric: false,
        label: 'זמין להזמנה',
        align: 'center',
        hide: true,
        filterable: false,
        editable: true,
        cellRenderer({ params }) {
            if (params?.node?.group) {
                if (params?.value) return (params?.value === true || params?.value === "true") ? 'כן זמין' : 'לא זמין';1    ;;      1;
                return ""
            }
            return (
                <>
                    {params?.data?.available ?
                        <span className='ag-icon ag-icon-tick content-icon' />
                        :
                        <span className='ag-icon ag-icon-cross content-icon' />}
                </>
            )
        }
    },
    {
        id: 'master',
        numeric: true,
        label: 'מוצר אבא לחיוב',
        align: 'center',
        hide: true,
        filterable: false,
        editable: true,

    },
];

const MultiProductsActions = (selected) => (
    <>
        <Tooltip title='יצא לPDF'>
            <GenerativeLoader func={(setLoader) => {

                getFile(false, () => pricesPdf(selected.customer.nkObject.masterBranch?.id ?? selected.customer.id, selected.date, selected.selected), `מחירי מוצרים ${selected.customer.name}.pdf`, 'application/octet-stream', undefined, setLoader);
            }} icon={<Chip
                icon={ <Avatar variant='square'
                               sx={{ cursor: 'pointer', bgcolor: 'white', width: '24px', height: '24px' }}
                               src={pdf} />}
                label={'ייצא מחירים לPDF'}
                style={{ cursor: 'pointer' }}
            />
               } />

        </Tooltip>
        {selected.noPrice ? undefined:<>
            <AllProductPriceChangesDialog data={selected} type={0} icon={<Chip
                icon={<CurrencyShekel
                />}
                label={'שינוי מחיר למסומנים'}
                style={{ cursor: 'pointer' }}
            />} tooltip={'שינוי מחיר גורף'} />
            <AllProductPriceChangesDialog data={selected} type={1} icon={<Chip
                icon={<Percentage />}
                label={'שינוי הנחה למסומנים'}
                style={{ cursor: 'pointer' }}
            />} tooltip={'שינוי הנחה גורף'} />

        </>}


        <AllProductAvailableChangesDialog data={selected} type={0} icon={
            <MyChip
                icon={<CheckCircleOutline />}
                label={'שינוי זמינות למסומנים'}
                variant="outlined"
                sx={{
                    ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                        color: '#000080',
                    },
                }}
            />} tooltip={'שינוי זמינות גורף'}
                                           />
    </>
);


const PricesList = ({ cid }: { cid: string }) => {
    const dispatch = useDispatch();
    const [checked, setChecked] = React.useState(false);

    const [radio, setRadio] = React.useState('from');
    const [date, setDate] = React.useState(new Date());
    const [toDate, setToDate] = React.useState(undefined);
    const [isMutating, setIsMutating] = React.useState(false);
    const [onlytAvailable, setOnlyAvailable] = React.useState( localStorage.getItem('onlyAvailable') === 'true');
    const [heads, setHead] = React.useState<HeadCell[]>(columns);
    const customers: Customer[] = useSelector(selectActiveCustomers);


    React.useEffect(() => {
        if (!customers || customers.length == 0)
            dispatch(getCustomers());
    }, [dispatch]);
    const cache = useQueryClient();
    const { data: products, isLoading, refetch } = useGetCustomerProductsPrices(cid, dateObjectToString(date), checked, true,true);
    React.useEffect(() => {
        if (!isLoading) {
            cache.removeQueries(CUSTOMER_PRODUCTS);

            refetch();
        }

    }, [date, checked]);
    React.useEffect(() => {
       setToDate(new Date())

    }, [radio]);

    const customer = useSelector((state: RootState) => selectCustomerById(state, cid));
    React.useEffect(() => {
        if (!customer) dispatch(getSpecificCustomer(Number(cid)));
    }, [dispatch]);

    React.useEffect(() => {
        heads[6].label = (customer?.includeTax ?? true) ? 'מחיר כולל מעמ' : 'מחיר לא כולל';
    }, [customer]);
    const copyFromClient = React.useCallback(
        async (from: number, d: Date) => {
            try {
                await copyClientPrice(from, Number(cid), date, d, radio != 'from' ? toDate : undefined, radio == 'unique');
                refetch();
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    message: `מחירים הועתקו!`,
                    variant: 'alert',
                    alertSeverity: 'success'
                });

            } catch (e) {
                console.log(e);
                Swal.fire({
                    title: 'שגיאה בגישה לנתונים',
                    target: '#custom-target',
                    text: '',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#d63030'
                });

            }
        },
        [date, radio]
    );

    const [menuItems, setMenuItems] = React.useState<{
        available: {
            label: string;
            value: boolean;
        }[]
    }>({
        available: [{
            label: "זמין",
            value: true,
        },
        {
            label: "לא זמין",
            value: false,
        },
        ],
    });

    const chipDetails = useMemo(() => {
        return [
            {
                action: () => {
                    !checked && setChecked(true);
                    checked && setChecked(false);
                },
                label: () => checked ? "הצג רק את מוצרי הלקוח" : "הצג את כל מוצרי המערכת",
                icon: <IconInfoCircle />,
                tooltip: 'הצג את כל המוצרי המערכת',
                active: () => checked,
            },

            {
                action: (params, setLoader) => {
                    customer && getFile(false, () => pricesPdf(customer?.nkObject?.masterBranch?.id ?? customer?.id, date), `מחירי מוצרים ${customer?.name}.pdf`, 'application/octet-stream', undefined, setLoader);
                },
                label: () => "יצא לPDF",
                icon: <Avatar variant='square'
                    sx={{ cursor: 'pointer', bgcolor: 'white', width: '24px', height: '24px' }}
                    src={pdf} />,
                tooltip: 'יצא לPDF',
                active: () => false,
                withLoader: true
            },
            {
                authButton: <DialogChooseAction withDate={date} chooseFrom={customers.map((c) => {
                    return { id: Number(c.id), name: c.name };
                }
                )} icon={<CopyAll />}
                    tooltip={'העתק מחירים מלקוח אחר'} onClose={(v, d) => {
                        copyFromClient(v.id, d ?? date);
                    }} title={'העתק מחירים מלקוח אחר'} multi={false} />,

                action: () => {

                },
                label: () => "",
                icon: <div></div>,

                tooltip: '',
                active: () => false,
            },
            {
                label: () => "רק מוצרים זמינים",
                action: (params) => {
                    setOnlyAvailable(!onlytAvailable)
                    localStorage.setItem('onlyAvailable', (!onlytAvailable).toString());
                },
                icon: <CheckCircleOutlinedIcon />,
                tooltip: "הצג האם המוצר זמין להזמנה",
                active: () => onlytAvailable,
            }
        ]
    }, [checked, customer, date, copyFromClient,onlytAvailable]);

    const saveDataCallback = React.useCallback(async (data) => {
        try {
            const toDateTemp = radio == 'from' ? undefined : toDate
            let availables = data.map(d => Number(d.available));
            const x = await updateAllPrice(data.map(d => {
                const client = JSDB().getClient(Number(cid)).first

                return ({
                    id:  Number(cid),
                    product_id: d.id,
                    price: d.totalPrice,
                    discount: d.discount,
                    master:d.master && d.master!=-1 ? d.master : -1

                })
            }), dateObjectToString(date), toDateTemp ? dateObjectToString(toDateTemp) : undefined, false,undefined,availables);
            refetch()
            return true
        } catch (e) {
            return false
        }

    }, [date, toDate, cid, radio]);
    const selectionControls = useCallback((selected) => {
        return (
            customer && customer.priceControl <= -10 ? <MultiProductsActions selected={selected?.map((p) => p?.id)}
                                                                             customer={customer}
                                                                             ids={[Number(cid)]} date={date} toDate={toDate}
                                                                             radio={radio}
                                                                             noPrice={true}
                                                                             extractor={(x) => x?.id} /> : <div style={{ display: "flex", position: "absolute", left: 0, marginTop: -35, top: 0 }}>
                <MultiProductsActions selected={selected?.map((p) => p?.id)}
                    customer={customer}
                    ids={[Number(cid)]} date={date} toDate={toDate}
                    radio={radio}
                    extractor={(x) => x?.id} />
            </div>

        )
    }, [customer, date, toDate, cid, radio]);

    const tableControls: ITableControls = {
        tableName: "costumers/costumers-list/costumers-prices",
        sidebar: true,
        allowEditSameValue: true,
        groupBy: {
            enable: true,
            rowGroup: true,
        },
        disableEdit: customer && customer.priceControl <= -10,
        selection: {
            enable: true,
            mode: "multiple",
        },
        cellRenderer: (params: any, column: any, i: number) => {
            if (params?.node?.rowPinned) return params.value || "";
            if (column.cellRenderer) {
                return column.cellRenderer({
                    params,
                });
            }
            if (typeof params?.value === "object") {
                const value = params?.value?.value || "";
                return value;
            }
            return params.value || "";
        },
    };
    return (
        <>
            {(isLoading || isMutating) && <Loader />}
            <Box sx={{ pb: 3 }}>
                {/*<ReactVirtualizedTable/>*/}
                <PricesFilterBar
                    // checked={checked} handleChecked={handleChecked}
                    radio_value={radio}
                    handleRadioChange={setRadio} date={date} setDate={setDate} toDate={toDate}
                    setToDate={setToDate} data={products} titles={columns} customer={customer} />
            </Box>
            <AgTable
                data={(onlytAvailable ? products?.filter((p)=>p.available) : products) ?? []}
                columns={heads}
                tableControls={tableControls}
                chipDetails={chipDetails}
                saveDataCallback={saveDataCallback}
                selectionControls={selectionControls}
                pinnedControl={basicPinnedControl()}
                menuiItems={menuItems}
            />
        </>
    );
};

export default memo(PricesList);
