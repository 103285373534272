/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';

// material-ui
import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, Input, makeStyles, MenuItem, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';
import { getSpecificCustomer, selectCustomerById } from 'store/customers/customersSlice';
import { RootState } from 'store';

// project imports
import config from 'config';
import SubCard from 'ui-component/cards/SubCard';
import Logo from 'ui-component/Logo';
import MyChip from 'ui-component/extended/Chip';

import { IconCurrencyShekel } from '@tabler/icons';

// Locale
import { format, parseJSON } from 'date-fns';
import { enUS, he } from 'date-fns/locale';
import { FormattedMessage } from 'react-intl';

// types
import { PayNote } from 'types/DocumentsTypes/PayNote';
import { Payment, PaymentMethod, paymentMethods } from 'types/DocumentsTypes/utils';
import DocumentHeaderPreview from '../document_header_preview/DocumentHeaderPreview';
import DocumentClientDetails from '../document_header_preview/DocumentClientDetails';
import NoNegative from 'ui-component/textFieldNumber';
import HoverableIncrementer from 'ui-component/HoverableIncrementer';

import JSDB from 'fromKotlin/nk';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import PreviewDocumentComments from "../PreviewComments";



interface ReceiptPreviewProps extends PayNote {
    onPaymentChange?: (payment: Payment) => void;
    creditRecepitValue?: boolean;
    setCreditRecepitValue?: React.Dispatch<React.SetStateAction<boolean>>
}



const ReceiptPreview = ({ onPaymentChange, creditRecepitValue, setCreditRecepitValue, ...document }: ReceiptPreviewProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // TODO: Change to single customer fetching
    const customer = useSelector((state: RootState) => selectCustomerById(state, document.entId));
    React.useEffect(() => {
        if (!customer) dispatch(getSpecificCustomer(Number(document.entId)));
    }, [dispatch]);

    if (!customer) return <Box />;
    const address = document.details!.street && document.details!.streetNumber ? `${document.details!.street}, ${document.details!.streetNumber}` : document.details!.street;


    return (
        <SubCard
            darkTitle
            title={
                <Typography fontWeight="bold">
                    <FormattedMessage id="Receipt" /> {document.id ? document.id : ''}
                </Typography>
            }
            secondary={<Logo width={50} />}
        >
            <Grid container spacing={2}>
                <DocumentHeaderPreview />
                <Grid item xs={12}>
                    <Grid container>
                        <DocumentClientDetails address={address} name={customer.name}
                            businessName={document.details!.companyName}
                            businessId={document.details!.companyId} id={customer.id} external_id={customer.externalId}
                            phone={document.details!.phone} email={document.details!.email} />
                        <Grid item sm={6}>
                            <Grid container>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    <FormattedMessage id="Receipt Details" />
                                </Typography>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography variant="body2">
                                                <FormattedMessage id="Date" />:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body2">
                                                {format(document.date, 'PPP', {
                                                    locale: config.i18n === 'he' ? he : enUS
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2'>
                                                תאריך הנפקה:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant='body2'>
                                                {format(document.issueDate, 'PPPPp', {
                                                    locale: config.i18n === 'he' ? he : enUS
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2">
                                                <FormattedMessage id="Status" />:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <MyChip
                                                label={<FormattedMessage id={document.status} />}
                                                variant="outlined"
                                                size="small"
                                                chipcolor={
                                                    document.status === 'pending_approval'
                                                        ? 'warning'
                                                        : document.status === 'approved'
                                                            ? 'success'
                                                            : document.status === 'open'
                                                                ? 'error'
                                                                : ''
                                                }
                                            />
                                        </Grid>
                                        <>
                                            <Grid item xs={4}>
                                                <Typography variant='body2'>
                                                    סוכן:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant='body2'>
                                                    {document?.agent}
                                                </Typography>
                                            </Grid>
                                        </>
                                        {document.receiptsIds?.length > 0 ? (
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    <FormattedMessage id="Invoice ID's" />:
                                                </Typography>
                                            </Grid>
                                        ) : undefined}
                                        {document.receiptsIds?.map((d, i) => (
                                            <Grid key={i} item xs={4}>
                                                <Typography variant="subtitle1">{d.toString()}</Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item sm={12}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                <FormattedMessage id="Payment Methods" />
                            </Typography>
                        </Grid>
                        {document.payments.map((p) => (
                            <React.Fragment key={p.id}>
                                <Grid item sm={9}>
                                    {!onPaymentChange ? (
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', }}>

                                            <Typography variant="subtitle1">
                                                {paymentMethods[p.method].name}
                                            </Typography>
                                            <Typography variant="subtitle1" style={{ direction: 'ltr' }}>
                                                {p.amount}
                                                <IconCurrencyShekel size={10} color="black" />
                                            </Typography>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', }}>
                                            <TextField
                                                id='outlined-select-currency'
                                                select
                                                label='בחר/י שיטת תשלום'
                                                sx={{ width: '50%', mb: 1, mt: 1 }}
                                                value={p.method}
                                                onChange={(m) => onPaymentChange({ ...p, method: m.target.value as PaymentMethod })}
                                            >
                                                {Object.keys(paymentMethods).map((p) => (
                                                    <MenuItem key={p} value={p}>
                                                        {paymentMethods[p].name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <HoverableIncrementer

                                                value={p.amount}
                                                disabled={false}

                                                onIncrement={() => {
                                                    onPaymentChange({
                                                        ...p,
                                                        amount: p.amount + 1
                                                    });
                                                }}
                                                onDecrement={() => {
                                                    onPaymentChange({
                                                        ...p,
                                                        amount: Math.max(0, p.amount - 1)
                                                    });
                                                }}
                                                onChange={(v) => {
                                                    onPaymentChange({ ...p, amount: Math.max(0, Number(v)) });
                                                }}
                                            />
                                        </div>

                                    )}

                                </Grid>
                                {p.check && p.method === 'check' && (

                                    <>
                                        <Grid item sm={4}>
                                            {!onPaymentChange ? (
                                                <Typography variant="subtitle2">
                                                    <FormattedMessage id="Account" />: {p.check.account}
                                                </Typography>
                                            ) : (
                                                <div>
                                                    <Typography variant="subtitle2">
                                                        <FormattedMessage id="Account" />:
                                                    </Typography>
                                                    <HoverableIncrementer
                                                        value={p.check.account}
                                                        disabled={false}
                                                        onIncrement={() => {
                                                            {
                                                                ((p.check && p.check.account) || p.check?.account === 0) &&
                                                                    onPaymentChange({
                                                                        ...p,
                                                                        check: { ...p.check, account: +(p.check.account) + 1 }
                                                                    });
                                                            }
                                                        }}
                                                        onDecrement={() => {
                                                            {
                                                                (p.check && p.check.account) &&
                                                                    onPaymentChange({
                                                                        ...p,
                                                                        check: { ...p.check, account: Math.max(0, +(p.check.account) - 1) }
                                                                    });
                                                            }
                                                        }}
                                                        onChange={(v) => {
                                                            onPaymentChange({ ...p, check: { ...p.check, account: (v) } });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item sm={3}>
                                            {!onPaymentChange ? (
                                                <div>
                                                    <Typography variant="subtitle2">
                                                        <FormattedMessage id="Branch" />: {p.check.branch}
                                                    </Typography>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Typography variant="subtitle2">
                                                        <FormattedMessage id="Branch" />:
                                                    </Typography>
                                                    <HoverableIncrementer
                                                        value={p.check.branch}
                                                        disabled={false}

                                                        onIncrement={() => {
                                                            {
                                                                ((p.check && p.check.branch) || p.check?.branch === 0) &&
                                                                    onPaymentChange({
                                                                        ...p,
                                                                        check: { ...p.check, branch: +(p.check.branch + 1) }
                                                                    });
                                                            }
                                                        }}
                                                        onDecrement={() => {
                                                            {
                                                                (p.check && p.check.branch) &&
                                                                    onPaymentChange({
                                                                        ...p,
                                                                        check: { ...p.check, branch: Math.max(0, +(p.check?.branch) - 1) }
                                                                    });
                                                            }
                                                        }}
                                                        onChange={(v) => {
                                                            onPaymentChange({ ...p, check: { ...p.check, branch: (v) } });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Grid item sm={3}>
                                                {onPaymentChange ? (
                                                    <div>
                                                        <Typography variant="subtitle2">
                                                            <FormattedMessage id="Bank" />
                                                        </Typography>
                                                        <Autocomplete
                                                            id='bank'
                                                            size='small'
                                                            sx={{
                                                                // border: "transparent",
                                                                "& .MuiOutlinedInput-root": {

                                                                    borderRadius: "50",
                                                                    border: "transparent",
                                                                    background: "transparent",
                                                                    padding: 1,
                                                                    width: 180

                                                                },
                                                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                                    border: "transparent",

                                                                }
                                                            }}
                                                            fullWidth
                                                            label={'מזהה בנק'}
                                                            type='number'
                                                            value={p.check?.bank ?? 0}
                                                            onChange={(event, value) => {
                                                                if (value) onPaymentChange({ ...p, check: { ...p.check, bank: (value) } });
                                                            }}
                                                            options={JSDB().getPaymentMethods().toArray() ?? []}

                                                            autoHighlight
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}

                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <>
                                                                                {params.InputProps.endAdornment}
                                                                            </>
                                                                        )
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div style={{width:'95px'}}>
                                                        <Typography variant="subtitle2" >
                                                            <FormattedMessage id="Bank" />: {p.check.bank}
                                                        </Typography>

                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={2} />
                                        <Grid item sm={4}>

                                            {!onPaymentChange ? (
                                                <Typography variant="subtitle2">
                                                    <FormattedMessage id="Check No." />: {p.check.id}
                                                </Typography>
                                            ) : (
                                                <div>
                                                    <Typography variant="subtitle2">
                                                        <FormattedMessage id="Check No." />
                                                    </Typography>
                                                    <HoverableIncrementer
                                                        value={p.check.id}
                                                        disabled={false}

                                                        onIncrement={() => {
                                                            {
                                                                ((p.check && p.check.id) || p.check?.id === 0) &&
                                                                    onPaymentChange({
                                                                        ...p,
                                                                        check: { ...p.check, id: +(p.check.id) + 1 }
                                                                    });
                                                            }
                                                        }}
                                                        onDecrement={() => {
                                                            {
                                                                (p.check && p.check.id) &&
                                                                    onPaymentChange({
                                                                        ...p,
                                                                        check: { ...p.check, id: Math.max(0, +(p.check?.id) - 1) }
                                                                    });
                                                            }
                                                        }}
                                                        onChange={(v) => {
                                                            onPaymentChange({ ...p, check: { ...p.check, id: (v) } });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item sm={7}>
                                            {!onPaymentChange ? (
                                                <Typography variant="subtitle2">
                                                    <FormattedMessage id="Due Date" />:{' '}
                                                    {format(
                                                        p.check?.dueDate
                                                            ? typeof p.check.dueDate === 'object'
                                                                ? p.check.dueDate
                                                                : parseJSON(p.check.dueDate)
                                                            : document.date,
                                                        'PP',
                                                        {
                                                            locale: config.i18n === 'he' ? he : enUS
                                                        }
                                                    )}
                                                </Typography>

                                            ) : (
                                                <div style={{ margin: 6 }} >
                                                    <LocalizationProvider sx={{ mt: 3 }} dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                                                        <DatePicker

                                                            mask=''
                                                            renderInput={(props) => <TextField {...props} />}
                                                            label={<FormattedMessage id='Due Date' />}
                                                            value={p.check?.dueDate}
                                                            onChange={(newValue) => {
                                                                if (newValue) onPaymentChange({
                                                                    ...p,
                                                                    check: { ...p.check, dueDate: newValue }
                                                                });
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item sm={1} />
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
                {
                    document?.recepitValue - document?.mainValue > 0 ? (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={creditRecepitValue} onChange={(e) => setCreditRecepitValue(e.target.checked)} />
                                }
                                label={`זכה סכום שנותר מהחשבוניות (${document?.recepitValue - document?.mainValue} ש"ח) `}
                            />
                        </Grid>
                    ) : undefined
                }
                <Grid item xs={12}>
                    <SubCard
                        sx={{
                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6} >
                                <FormattedMessage id="Total" />:
                            </Grid>
                            <Grid item xs={6} style={{ direction: 'ltr' }}>
                                <Typography variant="subtitle1" fontWeight="bold" >
                                    {document.mainValue.toLocaleString()}
                                    <IconCurrencyShekel size={10} color="black" />
                                </Typography>
                            </Grid>
                        </Grid>

                        {/*<Grid item xs={12}>*/}
                        {/*    <Grid item xs={12}>*/}
                        {/*        <PreviewDocumentComments comment={document.comment}  doc={ document}/>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </SubCard>
                </Grid>
            </Grid>
        </SubCard >
    );
};

export default memo(ReceiptPreview);


