import JSDB, {ApiCall, NKTypeConvertor} from 'fromKotlin/nk';
import {ActiveUpdate} from 'store/utls/utils';
import Customer, {
    serializeAddress,
    serializeDays,
    serializeNoTaxClient,
    serializePrintState,
    serializeTax
} from 'types/customer';

export const getCustomersAsync = async (withCreation: boolean = false) => {
    const req = new ApiCall('GET_CLIENT');
    req.putParam('date', JSDB().getDatesManipulator().dateNowNoHour());
    req.putParam('withDaily', true);
    if (withCreation)
        req.putParam('withCreation', true);
    return req.execute();
};
export const fillCustomersAsync = async () => {
    return JSDB().getAllClient();
};
export const getSpecificCustomersAsync = async (id: number) => {
    const req = new ApiCall('FETCH_CLIENT_DATA');
    req.putParam('id', id);
    const result = await req.execute();
    return id;
};

export const getCustomersCreationAsync = async () => {
    const req = new ApiCall('GET_CLIENT_CREATION');
    return await req.execute();
};

export const addCustomerAsync = async (c: Customer) => {
    const req = new ApiCall('NEW_CLIENT');

    const date = JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour());
    const phone = JSDB().preparePhone(c.phone, c.phone2, c.contactName, c.contactName2,c.phone3, c.contactName3);
    const branch = c?.branch ? c.branch : -1;
    const master = c?.master ? c.master : -1;
    const day = c?.freeDay ? -1: serializeDays(c)
    // Change to -
    // Object.keys(c).forEach((k) => req.putParam(k, c[k]));
    // Need to map type key to param name

    req.putParam('date', date);
    req.putParam('name', c.name);
    req.putParam('agent', c.agent);
    if (c.id)
        req.putParam('id', c.id);
    req.putParam('include_tax', Number(serializeTax(c)));
    req.putParam('no_tax_client', Number(serializeNoTaxClient(c)));
    req.putParam('business_name', c.businessName);
    req.putParam('business_id', c.businessId);
    req.putParam('address', serializeAddress(c));
    req.putParam('print_state', serializePrintState(c));
    req.putParam('phone', phone.first);
    req.putParam('category', c.category);
    req.putParam('personalNamePhone', phone.second);
    req.putParam('branch', branch);
    req.putParam('master', master);
    if (c.comments)
        req.putParam('comments', c.comments);
    req.putParam('email', c.email);
    req.putParam('price_control', Number(c.priceControl));
    req.putParam('obligo', c.obligo);
    req.putParam('discount', Number(c.discount));
    req.putParam('external_id', String(c.externalId));
    req.putParam('day', day);
    req.putParam('notes', Number(c.notes));
    req.putParam('min_order', Number(c.min_order));
    req.putParam('min_order_sum', Number(c.min_order_sum));
    req.putParam('location', (c.location));
    req.putParam('notes2', (c.notes2));
    req.putParam('notes3', (c.notes3));
    req.putParam('notes4', (c.notes4));
    req.putParam('days_to_pay', (c.days_to_pay));
    req.putParam('date_contact', (c.date_contact));

    if (c.paymentComments)
        req.putParam('payment_notes', (c.paymentComments));
    if (c.group)
        req.putParam('category2', (c.group));
    return req.execute();
};

function customerToNewObject(c: Customer): any {
    const phone = `${c.phone},${c.phone2},${c.phone3}`
    const phoneName = `${c.contactName},${c.contactName2},${c.contactName3}`

    const x = {
        id: c.id,
        date: c.date,
        name: c.name,
        agent: c.agent,
        include_tax: serializeTax(c), // Replace with the appropriate serialization function
        no_tax_client: Number(serializeNoTaxClient(c)), // Replace with the appropriate serialization function
        business_name: c.businessName.replace('"', "''"),
        business_id: c.businessId,
        address: serializeAddress(c), // Replace with the appropriate serialization function
        print_state: serializePrintState(c), // Replace with the appropriate serialization function
        phone: phone,
        phone_contact: phoneName,
        category: c.category,
        branch: c.nkObject.branch,
        master: c.nkObject.master,
        position: c.position,
        comments: c.comments ??'',
        email: c.email,
        discount: c.discount,
        external_id: c.externalId.toString(),
        day: serializeDays(c), // Replace with the appropriate serialization function
        notes: Number(c.notes),
        min_order: c.min_order,
        min_order_sum: c.min_order_sum,
        location: c.location,
        obligo: c.obligo,
        price_control: c.priceControl,
        //edi
        edi_sup_id: c.edi_sup_id,
        edi_client_id: c.edi_client_id,
        net_edi: c.net_edi,
        internal_sup_edi: c.internal_sup_edi,
        branch_number: c.branch_number,
        tax_note_type: c.tax_note_type,
        payment_notes: c.paymentComments,
        days_to_pay: c.days_to_pay,
        date_contact: c.date_contact,
        notes2: c.notes2,
        notes3: c.notes3,
        notes4: c.notes4,


    }
    if(c.driver)
        x.driver = c.driver;
    if(c.group)
        x.category2=c.group
    if(c.date_contact){

        const cd = c.date_contact.split("-")
        if(cd.length == 3){
            const day= cd[2].length!='4' ? cd[2] : cd[0]
            const month = cd[1]
            const year = cd[2].length!='4' ? cd[0] : cd[2]
            x.date_contact = `${year}-${month}-${day}`
        }


    }


    return x;
}

export const updateCustomerAsync = async (c: Customer) => {
    const req = new ApiCall('UPDATE_CLIENT');
    const date = JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour());
    const phone = JSDB().preparePhone(c.phone, c.phone2, c.contactName, c.contactName2,c.phone3, c.contactName3);
    const branch = c.branch ?? c.nkObject.branch;
    const master = c.master ?? c.nkObject.master;
    const day = c?.freeDay ? -1: serializeDays(c)
    // Change to -
    // Object.keys(c).forEach((k) => req.putParam(k, c[k]));
    // Need to map type key to param name

    req.putParam('id', c.id);
    req.putParam('date', date);
    req.putParam('name', c.name);
    req.putParam('agent', c.agent);
    req.putParam('include_tax', serializeTax(c));
    req.putParam('no_tax_client', Number(serializeNoTaxClient(c)));
    req.putParam('business_name', c.businessName.replace('"', '\'\''));
    req.putParam('business_id', c.businessId);
    req.putParam('address', serializeAddress(c));
    req.putParam('print_state', serializePrintState(c));
    req.putParam('phone', phone.first);
    req.putParam('personalNamePhone', phone.second);
    req.putParam('category', c.category);
    req.putParam('branch', branch);
    req.putParam('master', master);
    if (c.comments)
        req.putParam('comments', c.comments);
    req.putParam('email', c.email);
    req.putParam('obligo', c.obligo);
    req.putParam('discount', c.discount);
    req.putParam('external_id', String(c.externalId));
    if(c.driver)
    req.putParam('driver', c.driver);
    req.putParam('price_control', Number(c.priceControl));
    req.putParam('day',day );
    req.putParam('notes', Number(c.notes));
    req.putParam('min_order', Number(c.min_order));
    req.putParam('min_order_sum', Number(c.min_order_sum));
    req.putParam('location', (c.location));
    req.putParam('tax_note_type', c.tax_note_type);
    req.putParam('days_to_pay', c.days_to_pay);
    req.putParam('date_contact', c.date_contact);
    req.putParam('notes2', c.notes2);
    req.putParam('notes3', c.notes3);
    req.putParam('notes4', c.notes4);


    if (c.paymentComments)
        req.putParam('payment_notes', (c.paymentComments));
    if (c.group)
        req.putParam('category2', (c.group));
    if(c.date_contact)
        req.putParam('date_contact', c.date_contact);

    return req.execute();
};

export const deleteCustomerAsync = async (active: ActiveUpdate) => {
    const req = new ApiCall('UPDATE_CLIENT');
    const date = JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour());
    return req.putParam('id', Number(active.id)).putParam('date', date).putParam('active', Number(active.active)).execute();
};

export const changeAllCustomers = async (customers: Customer[]) => {
    const req = new ApiCall('UPDATE_CLIENT_ALL');
    return req.putParam('mapper', (NKTypeConvertor().jsToKotlinMap(customers.map(customerToNewObject)))).putParam("ids", NKTypeConvertor().toKotlinList(customers.map((c) => Number(c.id)))).execute();
};
