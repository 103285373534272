import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addCustomer,
    initAddCustomerAsyncState,
    initUpdateCustomerAsyncState,
    selectAddCustomerAsyncState,
    selectCustomerById,
    selectCustomersCategories,
    selectCustomersGroups,
    selectCustomersNotes2,
    selectCustomersNotes3, selectCustomersNotes4,
    selectCustomersObjs,
    selectPriceMenus,
    selectUpdateCustomerAsyncState,
    updateCustomer
} from 'store/customers/customersSlice';
import { getAgents, selectAgentsNames, selectGetAgentsAsyncState } from 'store/agents/agentsSlice';
import Customer, { makeCustomer } from 'types/customer';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import 'sweetalert2/src/sweetalert2.scss';
// material-ui
import {
    AppBar,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress, Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Switch,
    TextField, Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '@mui/lab/LoadingButton';

// third party
import * as yup from 'yup';
import { useFormik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { SNACKBAR_OPEN } from 'store/actions';
import { Mail, MapPin, Phone } from 'tabler-icons-react';
import JSDB from '../../fromKotlin/nk';
import NoNegative, { NoNegativeInput, WithNegative } from '../../ui-component/textFieldNumber';
import { Link } from 'react-router-dom';
import { IconId } from '@tabler/icons';
import { roundDecimals } from '../../fromKotlin/nkutils';
import { Transition } from "./CustomerList";
import CloseIcon from "@mui/icons-material/Close";
import CustomerPreview from "./profile/documents/CustomerPreview";
import citiesAndStreets from "../../assets/citiesAndStreets.json";

// types
export interface AddCustomerProps {
    editable?: boolean;
    open: boolean;
    handleDrawerOpen: () => void;
    id: string;
    setPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
    updateCallBack?: () => void;
    branches?: {
        name: string,
        id: string,
        branch: number,
        master: number,
    }[];
}

const AddCustomerData = ({ editable = false, open, handleDrawerOpen, id, updateCallBack, branches }: AddCustomerProps) => {
    const dispatch = useDispatch();
    const [cities, setCities] = React.useState<{
        [key: string]: string[];
    }>(JSON.parse(JSON.stringify(citiesAndStreets)));
    const addCustomerAsyncState = useSelector(selectAddCustomerAsyncState);
    const updateCustomerAsyncState = useSelector(selectUpdateCustomerAsyncState);
    const customer: Customer = useSelector((state: any) => selectCustomerById(state, id));
    const priceMenus = useSelector(state => selectPriceMenus(state));
    const [sumMenu,setMenu] = React.useState<string[]>(['סיכום רגיל','סיכום תעודות','סיכום מוצרים כללי','סיכום מוצרים מפורט ','לא בשימוש','סיכום תעודות רשתות','סיכום מוצרים רשתות','לא בשימוש','לא בשימוש','לא בשימוש',"סיכום מוצרים עם תעודות"]);
    const agentsAsyncState = useSelector(selectGetAgentsAsyncState);
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [isBranch, setIsBranch] = React.useState(customer?.nkObject?.branch >= 0);
    const categories: string[] = useSelector((state: any) => selectCustomersCategories(state));
    const notes2: string[] = useSelector((state: any) => selectCustomersNotes2(state));
    const notes3: string[] = useSelector((state: any) => selectCustomersNotes3(state));
    const notes4: string[] = useSelector((state: any) => selectCustomersNotes4(state));
    const groups: string[] = useSelector((state: any) => selectCustomersGroups(state));
    const notes2d = JSDB().getUser().first?.getJsonDesc("cnotes2")
    let labelNotes2 = "הערות לקוח 2";
    if (notes2d) {
        labelNotes2 = notes2d.name;


    }
    const notes3d = JSDB().getUser().first?.getJsonDesc("cnotes3")
    let labelNotes3 = "הערות לקוח 3";
    if (notes3d) {
        labelNotes3 = notes3d.name;

    }

    const notes4d = JSDB().getUser().first?.getJsonDesc("cnotes4")
    let labelNotes4 = "הערות לקוח 4";
    if (notes4d) {
        labelNotes4 = notes4d.name;
    }

    const agents = useSelector(selectAgentsNames);
    const user = JSDB().getUser().first;
    React.useEffect(() => {
        if(agents.length === 0) {

            dispatch(getAgents());
        }
        dispatch(initAddCustomerAsyncState());
    }, [dispatch]);
    const validationSchema = yup.object({
        name: yup.string().required('שם הלקוח הינו שדה חובה'),
        agent: yup.string().required('שם הסוכן הינו שדה חובה').oneOf(agents, 'סוכן לא קיים'),
        businessName: yup.string().required('שם חברה הינו שדה חובה'),
        discount: yup.number().min(0).max(100),
        streetNumber: yup.number().min(0, 'מספר הרחוב אינו תקין'),
        phone: yup.number().min(0, 'מספר טלפון אינו תקין')
    });

    React.useEffect(() => {
        if (addCustomerAsyncState === 'succeeded') {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'הלקוח נוסף בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
            dispatch(initAddCustomerAsyncState());
            formik.resetForm();
            handleDrawerOpen();
        } else if (addCustomerAsyncState === 'failed') {
            Swal.fire({
                title: 'שגיאה בהוספת לקוח',
                target: '#custom-target',
                text: '',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'המשך'
            });
            dispatch(initAddCustomerAsyncState());
        }
    }, [addCustomerAsyncState, handleDrawerOpen, dispatch]);

    React.useEffect(() => {
        if (updateCustomerAsyncState === 'succeeded') {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'הלקוח עודכן בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
            dispatch(initUpdateCustomerAsyncState());
            if (updateCallBack)
                updateCallBack();
            handleDrawerOpen();
        } else if (updateCustomerAsyncState === 'failed') {
            Swal.fire({
                title: 'שגיאה בעדכון לקוח',
                target: '#custom-target',
                text: '',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'המשך'
            });
            dispatch(initUpdateCustomerAsyncState());
        }
    }, [updateCustomerAsyncState, handleDrawerOpen, dispatch]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: customer || makeCustomer({ withBarcode: user.withBarcode(), withReturns: user.withReturns(), withPrice: user.withPrice() }),
        validationSchema,
        onSubmit: (c) => {
            editable ? dispatch(updateCustomer(c)) : dispatch(addCustomer(c));
        }
    });

    const weekDays = [
        { value: "sunday", label: "ראשון" },
        { value: "monday", label: "שני" },
        { value: "tuesday", label: "שלישי" },
        { value: "wednesday", label: "רביעי" },
        { value: "thursday", label: "חמישי" },
        { value: "friday", label: "שישי" },
    ]
    return (
        <Box sx={{ p: 3 }}>
            <Tooltip title={<FormattedMessage id='customer-profile' />} onClick={() => {
                setPreviewOpen(true);
            }}>
                <Chip icon={<AccountBoxIcon size='medium' />} sx={{
                    ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                        color: '#000080',
                    },
                }} label="פרופיל לקוח" variant="outlined" />
            </Tooltip>
            <form onSubmit={formik.handleSubmit}>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={4} sm={4}>
                        <Typography variant='h3'>{editable ? 'עדכן לקוח' : 'הוסף לקוח'}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        {!customer ? <NoNegativeInput

                            placeholder={'אוטומטי'}
                            changer={(v) => {
                                formik.setFieldValue('id', v);

                            }}

                        /> : <Typography variant='h3'>{customer?.id}</Typography>}



                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <Tooltip onClick={() => formik.resetForm()} title={<FormattedMessage id='clear' />}>
                            <Chip icon={<DeleteSweepIcon size='medium' />} sx={{
                                ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                                    color: '#000080',
                                },
                            }} label="נקה" variant="outlined" />

                        </Tooltip>
                    </Grid>
                    <Grid item xs={7} sm={7}>
                        <TextField
                            fullWidth
                            id='name'
                            name='name'
                            label='שם הלקוח'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item xs={5} sm={5}>
                        <Grid container justifyContent='flex-start'>
                            <Autocomplete
                                id='agent'
                                inputValue={formik.values.agent}
                                onChange={(event, value) => {
                                    if (value) formik.setFieldValue('agent', value);
                                }}
                                onInputChange={(event, value) => {
                                    if (event) formik.setFieldValue('agent', value);
                                }}
                                onFocus={() => {
                                    if(agents.length === 0) {
                                        dispatch(getAgents());
                                    }
                                }}
                                options={agents ?? []}
                                loading={agentsAsyncState === 'loading'}
                                fullWidth
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='בחר קו'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {agentsAsyncState === 'loading' ? (
                                                        <CircularProgress color='inherit' size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            )
                                        }}
                                        error={formik.touched.agent && Boolean(formik.errors.agent)}
                                        helperText={formik.touched.agent && formik.errors.agent}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={2}>

                            <Grid item xs={6} sm={6}>
                                <FormControl>
                                    <FormControlLabel
                                        value='low'
                                        control={
                                            <Checkbox
                                                checked={formik.values.notes}
                                                onChange={(value) => {
                                                    formik.setFieldValue('notes', value.target.checked);
                                                }}
                                                color='primary'
                                                sx={{ color: 'primary.main' }}
                                            />
                                        }
                                        label='לוקח תעודות משלוח'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                {(user.no_tax_c == 1) ? <FormControl>
                                    <FormControlLabel
                                        value='medium'
                                        control={
                                            <Checkbox
                                                checked={formik.values.noTaxClient}
                                                onChange={(value) => {
                                                    formik.setFieldValue('noTaxClient', value.target.checked);
                                                }}
                                                color='primary'
                                                sx={{ color: 'primary.main' }}
                                            />
                                        }
                                        label='לקוח מיוחד ללא מעמ'
                                    />
                                </FormControl> : undefined}

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={2}>
                            <Grid item xs={3} sm={3}>
                                <Typography variant='h4'>מצב הדפסה:</Typography>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <FormControl>
                                    <FormControlLabel
                                        value='low'
                                        control={
                                            <Checkbox
                                                checked={formik.values.withBarcode}
                                                onChange={(value) => {
                                                    formik.setFieldValue('withBarcode', value.target.checked);
                                                }}
                                                color='primary'
                                                sx={{ color: 'primary.main' }}
                                            />
                                        }
                                        label='ברקוד'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <FormControl>
                                    <FormControlLabel
                                        value='medium'
                                        control={
                                            <Checkbox
                                                checked={formik.values.withReturns}
                                                onChange={(value) => {
                                                    formik.setFieldValue('withReturns', value.target.checked);
                                                }}
                                                color='primary'
                                                sx={{ color: 'primary.main' }}
                                            />
                                        }
                                        label='חזרות'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <FormControl>
                                    <FormControlLabel
                                        value='high'
                                        control={
                                            <Checkbox
                                                checked={formik.values.withPrice}
                                                onChange={(value) => {
                                                    formik.setFieldValue('withPrice', value.target.checked);
                                                }}
                                                color='primary'
                                                sx={{ color: 'primary.main' }}
                                            />
                                        }
                                        label='מחירים'
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems='center'>
                            <Grid item xs={3} sm={3}>
                                <Typography variant='h4'>אפשרויות:</Typography>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <FormControl>
                                    <FormControlLabel
                                        value='high'
                                        control={
                                            <Switch
                                                checked={formik.values.includeTax}
                                                onChange={(value) => {
                                                    formik.setFieldValue('includeTax', value.target.checked);
                                                }}
                                            />
                                        }
                                        label='כולל מעמ'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <FormControl>
                                    <FormControlLabel value='high' control={
                                        <Switch
                                            checked={isBranch}
                                            onChange={(value) => {
                                                setIsBranch(value.target.checked);
                                            }}
                                            disabled={id ? true : false}
                                        />}
                                        label='סניף רשת' />
                                </FormControl>
                            </Grid>


                            <Grid item xs={3} sm={3}>
                                <NoNegative
                                    id='discount'
                                    value={formik.values.discount}
                                    changer={(v) =>
                                        formik.setFieldValue('discount', Math.max(0, Math.min(Number(v), 100)))
                                    }
                                    label='הנחה קבועה (%)'
                                    size='small'
                                    error={formik.touched.discount && Boolean(formik.errors.discount)}
                                    helperText={formik.touched.discount && formik.errors.discount}
                                />
                            </Grid>
                            {isBranch && !id ?
                                <Grid item xs={12} sm={12} mt={2}>
                                    <Autocomplete
                                        id='branch'
                                        inputValue={formik.values.businessName}
                                        onChange={(event, value) => {
                                            const branch = branches?.find((b) => b.name == value);
                                            if (value) {
                                                formik.setFieldValue('master', branch?.master);
                                                formik.setFieldValue('branch', branch?.branch);
                                                formik.setFieldValue('businessName', value);
                                                formik.setFieldValue('businessId', branch?.id)
                                            }
                                        }}
                                        onInputChange={(event, value) => {
                                            const branch = branches?.find((b) => b.name == value);
                                            if (event) {
                                                formik.setFieldValue('master', branch?.master);
                                                formik.setFieldValue('branch', branch?.branch);
                                                formik.setFieldValue('businessName', value);
                                                formik.setFieldValue('businessId', branch?.id)
                                            }
                                        }}
                                        options={branches?.map((b) => b.name) ?? []}
                                        fullWidth
                                        autoHighlight
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='סניף'
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                : undefined
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Autocomplete
                            id='category'
                            inputValue={formik.values.category}
                            onChange={(event, value) => {
                                if (value) formik.setFieldValue('category', value);
                            }}
                            onInputChange={(event, value) => {
                                if (event) formik.setFieldValue('category', value);
                            }}
                            options={categories ?? []}
                            fullWidth
                            autoHighlight
                            freeSolo
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='קטגורית לקוחות'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <Autocomplete
                            id='group'
                            inputValue={formik.values.group}
                            onChange={(event, value) => {
                                if (value) formik.setFieldValue('group', value);
                            }}
                            onInputChange={(event, value) => {
                                if (event) formik.setFieldValue('group', value);
                            }}
                            options={groups ?? []}
                            fullWidth
                            autoHighlight
                            freeSolo
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='קבוצת לקוחות'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant='h4'>פרטי קשר</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    fullWidth
                                    id='businessName'
                                    name='businessName'
                                    label='שם החברה'
                                    value={formik.values.businessName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.businessName && Boolean(formik.errors.businessName)}
                                    helperText={formik.touched.businessName && formik.errors.businessName}
                                    disabled={isBranch && customer?.nkObject?.master!=-1}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    fullWidth
                                    id='businessId'
                                    name='businessId'
                                    label='ח.פ. \ ע.מ.'
                                    type='string'
                                    value={formik.values.businessId}
                                    onChange={formik.handleChange}
                                    disabled={isBranch && customer?.nkObject?.master!=-1}
                                />
                            </Grid>

                            <Grid item xs={4} sm={4}>
                                {/* <TextField
                                    fullWidth
                                    id='city'
                                    name='city'
                                    label='עיר'
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                /> */}
                                <Autocomplete
                                    id='city'
                                    inputValue={formik.values.city}
                                    onChange={(event, value) => {
                                        if (value) formik.setFieldValue('city', value);
                                        document?.getElementById('street')?.focus();
                                    }}
                                    onInputChange={(event, value) => {
                                        if (event) formik.setFieldValue('city', value);

                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            document?.getElementById('street')?.focus();
                                        }
                                    }}
                                    options={Object.keys(cities)}
                                    fullWidth
                                    autoHighlight
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='עיר'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Autocomplete
                                    id='street'
                                    inputValue={formik.values.street}
                                    onChange={(event, value) => {
                                        if (value) formik.setFieldValue('street', value);
                                        document?.getElementById('streetNumber')?.focus();
                                    }}
                                    onInputChange={(event, value) => {
                                        if (event) formik.setFieldValue('street', value);

                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            document?.getElementById('streetNumber')?.focus();
                                        }
                                    }}
                                    options={cities[formik.values.city] ?? []}
                                    fullWidth
                                    autoHighlight
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='רחוב'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    fullWidth
                                    id='streetNumber'
                                    name='streetNumber'
                                    label='מספר'
                                    type='number'
                                    value={formik.values.streetNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.streetNumber && Boolean(formik.errors.streetNumber)}
                                    helperText={formik.touched.streetNumber && formik.errors.streetNumber}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    id='location'
                                    name='location'
                                    label='מיקום מדוייק GPS'
                                    type='text'

                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    error={formik.touched.location && Boolean(formik.errors.location)}
                                    helperText={'מיקום LAT    ,    LON'}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <MapPin size={14} strokeWidth={1} color="#000000" />
                                            </InputAdornment>
                                        )
                                    }}
                                />

                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id='email'
                                    name='email'
                                    label='אימייל'
                                    type='string'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Mail
                                                    size={18}
                                                    strokeWidth={2}
                                                    color='#000000'
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    fullWidth
                                    id='contactName'
                                    name='contactName'
                                    label='איש קשר'
                                    value={formik.values.contactName}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <TextField
                                    fullWidth
                                    id='phone'
                                    name='phone'
                                    label='טלפון'
                                    type='tel'
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Phone
                                                    size={18}
                                                    strokeWidth={2}
                                                    color='#000000'
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    fullWidth
                                    id='contactName2'
                                    name='contactName2'
                                    label='איש קשר 2'
                                    value={formik.values.contactName2}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <TextField
                                    fullWidth
                                    id='phone2'
                                    name='phone2'
                                    label='טלפון 2'
                                    type='tel'
                                    value={formik.values.phone2}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone2 && Boolean(formik.errors.phone2)}
                                    helperText={formik.touched.phone2 && formik.errors.phone2}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Phone
                                                    size={18}
                                                    strokeWidth={2}
                                                    color='#000000'
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    fullWidth
                                    id='contactName3'
                                    name='contactName3'
                                    label='איש קשר 3'
                                    value={formik.values.contactName3}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <TextField
                                    fullWidth
                                    id='phone3'
                                    name='phone3'
                                    label='טלפון 3'
                                    type='tel'
                                    value={formik.values.phone3}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone3 && Boolean(formik.errors.phone3)}
                                    helperText={formik.touched.phone3 && formik.errors.phone3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Phone
                                                    size={18}
                                                    strokeWidth={2}
                                                    color='#000000'
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        dir='ltr'
                                        inputProps={{ style: { textAlign: 'right' } }}
                                        id='externalId'
                                        name='externalId'
                                        label='מזהה חיצוני'
                                        value={formik.values.externalId}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <NoNegative
                                        fullWidth
                                        id='obligo'
                                        name='obligo'
                                        label='אובליגו'
                                        value={formik.values.obligo}
                                        changer={(v) => {
                                            formik.setFieldValue('obligo', roundDecimals(Math.max(0, Number(v)), 4));
                                        }}
                                        sx={{ mt: 2 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        dir='rtl'
                                        inputProps={{ style: { textAlign: 'right' } }}
                                        id='paymentComments'
                                        name='paymentComments'
                                        label='תנאי תשלום'
                                        value={formik.values.paymentComments}
                                        onChange={formik.handleChange}

                                    />

                                </Grid>
                                <Grid item>
                                    <NoNegative
                                        id='days_to_pay'
                                        value={formik.values.days_to_pay}
                                        changer={(v) =>
                                            formik.setFieldValue('days_to_pay', Math.max(0,Number(v)))
                                        }
                                        label='ימים לתשלום'
                                        size='small'
                                        sx={{ mt: 2 }}
                                    />

                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid item xs={3} sm={3}>
                                    <Typography variant='h4'>ימי חלוקה:</Typography>
                                </Grid>
                                <Grid item xs={9} sm={9}>
                                    <FormControl>
                                        <FormControlLabel value='high' control={
                                            <Switch
                                                checked={formik.values.freeDay}
                                                onChange={(value) => {
                                                    if(value.target.checked){
                                                        formik.setFieldValue('freeDay', true);
                                                    } else {
                                                        formik.setFieldValue('freeDay', false);

                                                    }

                                                }}

                                            />}
                                                          label='לקוח מזדמן' />
                                    </FormControl>
                                </Grid>
                                {
                                    weekDays.map((day, index) => {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                value='high'
                                                control={
                                                    <Checkbox
                                                        checked={formik.values[day.value]}
                                                        onChange={(value) => {
                                                            formik.setFieldValue(day.value, value.target.checked);
                                                        }}
                                                        color='primary'
                                                        sx={{ color: 'primary.main' }}
                                                    />
                                                }
                                                label={day.label}
                                            />
                                        )
                                    })
                                }
                            </Grid>
                            {user.note_price == 1 ? (
                                <>
                                    <Grid item xs={8} sm={8}>
                                        <Autocomplete
                                            id='priceControl'
                                            value={priceMenus.find((s) => s.id == Number(formik.values.priceControl))}
                                            onChange={(event, value) => {
                                                if (value) formik.setFieldValue('priceControl', value.id);
                                            }}

                                            options={priceMenus ?? []}
                                            getOptionLabel={(option) => option?.name ?? ''}
                                            fullWidth
                                            autoHighlight
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label='מחירון קבוע'
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <Button
                                            variant='contained'
                                            color='error'
                                            onClick={() => {
                                                formik.setFieldValue('priceControl', '');
                                            }}
                                        >
                                            נקה מחירון
                                        </Button>
                                    </Grid>
                                </>
                            )
                                : undefined}

                            <Grid item xs={12} sm={12}>
                                <Autocomplete
                                    id='tax_note_type'
                                    value={sumMenu.find((s,index) => index-1 == Number(formik.values.tax_note_type))}
                                    onChange={(event, value) => {
                                        if (value){
                                            const index = sumMenu.findIndex((s) => s == value);
                                            formik.setFieldValue('tax_note_type', index-1);
                                        }

                                    }}

                                    options={sumMenu ?? []}
                                    fullWidth
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='סוג סיכום בחשבונית'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    dir='rtl'
                                    inputProps={{ style: { textAlign: 'right' } }}
                                    id='comments'
                                    name='comments'
                                    label='הערות'
                                    value={formik.values.comments}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <NoNegative
                                    fullWidth
                                    id='min_order'
                                    name='min_order'
                                    label='מינימום הזמנה'
                                    value={formik.values.min_order}
                                    changer={(v) => {
                                        formik.setFieldValue('min_order', roundDecimals(Math.max(0, Number(v)), 4));
                                    }

                                    }
                                />
                            </Grid>
                                <Grid item xs={6} sm={6}>
                                <NoNegative
                                    fullWidth
                                    id='min_order_sum'
                                    name='min_order_sum'
                                    label='מינימום בכסף'
                                    value={formik.values.min_order_sum}
                                    changer={(v) => {
                                        formik.setFieldValue('min_order_sum', roundDecimals(Math.max(0, Number(v)), 4));
                                    }

                                    }
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Autocomplete
                                    id='notes2'
                                    inputValue={formik.values.notes2}
                                    onChange={(event, value) => {
                                        if (value) formik.setFieldValue('notes2', value);
                                    }}
                                    onInputChange={(event, value) => {
                                        if (event) formik.setFieldValue('notes2', value);
                                    }}
                                    options={notes2 ?? []}
                                    fullWidth
                                    autoHighlight
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={labelNotes2}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Autocomplete
                                    id='notes3'
                                    inputValue={formik.values.notes3}
                                    onChange={(event, value) => {
                                        if (value) formik.setFieldValue('notes3', value);
                                    }}
                                    onInputChange={(event, value) => {
                                        if (event) formik.setFieldValue('notes3', value);
                                    }}
                                    options={notes3 ?? []}
                                    fullWidth
                                    autoHighlight
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={labelNotes3}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Autocomplete
                                    id='notes4'
                                    inputValue={formik.values.notes4}
                                    onChange={(event, value) => {
                                        if (value) formik.setFieldValue('notes4', value);
                                    }}
                                    onInputChange={(event, value) => {
                                        if (event) formik.setFieldValue('notes4', value);
                                    }}
                                    options={notes4 ?? []}
                                    fullWidth
                                    autoHighlight
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={labelNotes4}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{
                        position: 'sticky',
                        bottom: 10,
                    }}>
                        <AnimateButton>
                            <LoadingButton
                                loading={addCustomerAsyncState === 'loading' || updateCustomerAsyncState === 'loading'}
                                fullWidth
                                variant='contained'
                                type='submit'
                            >
                                שמור
                            </LoadingButton>
                        </AnimateButton>
                    </Grid>
                </Grid>
            </form>
            {previewOpen && customer ? <Dialog TransitionComponent={Transition} fullScreen fullWidth open={previewOpen}
                onBackdropClick={() => {
                    setPreviewOpen(false)

                }}
                sx={{ '& .MuiDialog-paper': { p: 0 } }}>
                <AppBar sx={{ position: 'sticky' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setPreviewOpen(false)

                            }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                            סגור חלון
                        </Typography>
                        <Typography onClick={() => {
                            setPreviewOpen(false)

                        }}>
                            {customer?.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <CustomerPreview id={customer?.id} />
            </Dialog> : undefined}
        </Box>
    );
};
export default memo(AddCustomerData);
