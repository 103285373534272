import {
    dateObjectToString, dateTimeObjectToString, roundDecimals,
    StringToDateObject,
    StringToDateObjectAddTime,
    StringToDateTimeObject
} from '../../fromKotlin/nkutils';
import JSDB from '../../fromKotlin/nk';
import IItem from '../product_delivery';
import {addHours, parseJSON} from 'date-fns';
import {DocumentStatus, DocumentType, ExtendedDocument, ProductNote} from './Document';
import value from '../../assets/scss/_themes-vars.module.scss';
import {CartesetDataHolder} from '../CartesetDataHolder';
import {distinct} from '../../utils/sort';
import {HandLine} from "./TaxNote";


export const documentTypes = {
    invoice: {
        name: 'חשבונית מס',
        color: value.primaryLight,
        textColor: value.primary800,
        baseColor: 'error',
        nkType: 0
    },
    tax_invoice: {
        name: 'חשבונית מס קבלה',
        color: value.secondaryLight,
        textColor: value.secondaryDark,
        baseColor: 'secondary',
        nkType: 1
    },
    refund: {
        name: 'חשבונית זיכוי',
        textColor: value.grey900,
        color: value.warningMain,
        baseColor: 'warning',
        nkType: 2
    },
    delivery_certificate: {
        name: 'תעודת משלוח',
        color: value.successLight,
        textColor: value.successDark,
        baseColor: 'success',
        nkType: 4
    },
    supplier_note: {
        name: 'תעודת ספק',
        color: value.successLight,
        textColor: value.successDark,
        baseColor: 'success',
        nkType: 6
    },
    visit_note: {
        name: 'תעודת ביקור',
        color: value.successLight,
        textColor: value.successDark,
        baseColor: 'success',
        nkType: 12
    },
    all_tax: {
        name: 'מחזור',
        color: value.successLight,
        textColor: value.successDark,
        baseColor: 'success',
        nkType: 5
    },
    order: {name: 'הזמנה', color: value.grey100, textColor: value.orangeDark, baseColor: 'blue', nkType: 10},
    receipt: {name: 'קבלה', color: value.grey200, textColor: value.grey600, baseColor: 'gray', nkType: 7},
    debt: {name: 'חוב קודם', color: value.grey200, textColor: value.grey600, baseColor: 'gray', nkType: 9}
};

export interface Check {
    id?: number;
    bank?: string;
    branch?: number;
    account?: number;
    dueDate?: Date;
}

export type PaymentMethod = 'cash' | 'credit' | 'check' | 'deposit' | 'bit' | 'withholding_tax' | 'barter' |'fastCheck';
export const paymentMethods = {
    cash: {name: 'מזומן'},
    credit: {name: 'אשראי'},
    check: {name: 'צ\'ק'},
    deposit: {name: 'העברה בנקאית'},
    bit: {name: 'ביט'},
    withholding_tax: {name: 'ניכוי מס במקור'},
    barter: {name: 'ברטר'},
    fastCheck: {name: 'צ\'ק מהיר'},
};

export interface Payment {
    id: string;
    amount: number;
    method: PaymentMethod;
    check?: Check;
    paid?: number;
    paid_date?: string;
    paid_details?: string;
}


export const serializePayment = (p, i,default_date=undefined): Payment => {
    let method: PaymentMethod = 'cash';
    let check;
    if (p.payMethod == 'CASH') {
        method = 'cash';
    } else if (p.payMethod == 'CHECK') {
        method = 'check';
        const date = (StringToDateObject(JSDB().getDatesManipulator().dateIsrael(p.check_date)))


        default_date
        check = {
            id: p.check_number,
            bank: p.bankName(),
            branch: p.bank_branch,
            account: p.bank_number,
            dueDate: isNaN(date.getDate()) ? default_date : date
        };

    } else if (p.payMethod == 'CARD') {
        method = 'credit';
    } else if (p.payMethod == 'BANK') {
        const date = (StringToDateObject(JSDB().getDatesManipulator().dateIsrael(p.check_date)))
        check = {
            id: p.check_number,
            bank: p.bankName(),
            branch: p.bank_branch,
            account: p.bank_number,
            dueDate: isNaN(date.getDate()) ? default_date : date
        };
        method = 'deposit';
    } else if (p.payMethod == 'BIT') {
        method = 'bit';
    } else if (p.payMethod == 'MASS_BAMACOR') {
        method = 'withholding_tax';
    } else if (p.payMethod == 'BARTER') {
        method = 'barter';
    } else if (p.payMethod == 'FAST_CHECK') {
        method = 'fastCheck';
        const date = (StringToDateObject(JSDB().getDatesManipulator().dateIsrael(p.check_date)))
        check = {
            id: p.check_number,
            bank: p.bankName(),
            branch: p.bank_branch,
            account: p.bank_number,
            dueDate: isNaN(date.getDate()) ? default_date : date
        }
    }

    return {
        amount: p.value,
        check,
        id: i ?? '',
        method,
        paid: p?.paid,
        paid_date: p?.paid_date,
        paid_details: p?.paid_details

    };
};
export const deserializePaymentMethod = (m): number => {
    let method = 0;
    if (m === 'cash') {
        method = 1;
    } else if (m === 'check') {
        method = 2;
    } else if (m === 'credit') {
        method = 3;
    } else if (m === 'deposit') {
        method = 4;
    } else if (m === 'bit') {
        method = 5;
    } else if (m === 'withholding_tax') {
        method = 6;
    } else if (m === 'barter') {
        method = 7;
    } else if (m === 'fastCheck') {
        method = 8;
    }
    return method
}
export const deserializePayment = (payment: Payment): any => {
    let method = deserializePaymentMethod(payment.method);


    return JSDB().createPayNoteDataFromData(
        Number(payment.amount),
        method,
        payment.check?.bank ? payment.check?.bank : '',
        payment.check?.branch ? payment.check?.branch : '',
        payment.check?.account ? payment.check?.account : '',
        payment.check?.id ? payment.check?.id : '',
        payment.check?.dueDate ? JSDB().getDatesManipulator().dateIsrael(dateObjectToString(payment.check?.dueDate)) : ''
    );
};


export interface Details {
    id: number;
    companyName: string;
    companyId: string;
    city: string;
    street: string;
    streetNumber: string,
    phone: string;
    email: string
}

export const serializeDetails = (doc): Details => {
    const sd = doc.ClientStaticData;
    return {
        streetNumber: sd.streetNumber,
        city: sd.city,
        companyId: sd.business_id,
        companyName: sd.business_name,
        street: sd.street,
        id: sd.id,
        phone: sd.phone,
        email: ''
    };
};
export type visitStates = { label: "ביקור כללי", value: 0 }
    | { label: "ביצעתי הזמנה", value: 1 }
    | { label: "ביצעתי מכירה", value: 2 }
    | { label: "לא צריך סחורה", value: 3 }
    | { label: "לקחתי החזרות", value: 4 }
    | { label: "לקחתי תשלום", value: 5 }
    | { label: "הוספתי לקוח", value: 6 };

export const visitStatesMap = {
    "ביקור כללי": 0,
    "ביצעתי הזמנה": 1,
    "ביצעתי מכירה": 2,
    "לא צריך סחורה": 3,
    "לקחתי החזרות": 4,
    "לקחתי תשלום": 5,
    "הוספתי לקוח": 6


}
export const deserializeDetails = (details: Details): any => JSDB().createClientStaticData(Number(details.id), details.companyName, details.companyId, details.phone, details.city, details.street, details.streetNumber);

export function itemApiIInternal(p, date, client,): IItem {
    const product = p.getProduct();
    const op = product.getOrderProduct();
    let barcode = '';
    if (product.getBarcode() != '0000000000000')
        barcode = product.getBarcode();
    let unit1 = product.getUnitName();
    const units: string[] = [];
    if (unit1 == '') {
        unit1 = 'יח\'';
    }
    units.push(unit1);
    if (op != null) {
        const unit2 = op.getExtraUnitName();
        if (unit2 != null && unit2 != '') {
            units.push(unit2);
        }
    }

    const price = p.price;
    const discount = p.discount;
    const cost = JSDB().getProductCost(product.id)?.get(date)


    let totalCost = (cost?.first ?? 0) * (1 - (cost?.second ?? 0) / 100)

    // let totalCost=undefined


    return {
        discount,
        nkObject: p,
        quantityReturns: p.returns,
        quantitySecondReturns: p.wrapped_amount_return,
        totalPrice: price,
        id: product.id,
        external_id: product.getExternalId(),
        name: product.getName(),
        image: '',
        barcode,
        costPrice: totalCost,
        position: product.getPosition(),
        quantity: p.value,
        quantitySecond: p.wrapped_amount,
        no_tax_product: product.getNoTaxProduct() === 1,
        unit: unit1,
        in_unit: product.getUnitAmmount(date),
        category: product.getCategory(),
        category2: product.getCategory2(),
        available_units: units,
        notes: p.notes,
        notes2: p.notes2,
        notes3: p.notes3,
        conversion_ratio: p.conversion_ratio,
        totalPriceInclude:p.priceAfter,
        totalPriceExclude: p.priceBefore

    };
}

export function itemsApi(client: any, date: string, pds: any): IItem[] {
    const items: IItem[] = [];
    if (pds.size > 0)
        pds.toArray().forEach((p: any) => {

            items.push(itemApiIInternal(p, date, client));

        });


    return items;


}


export const createDocument = ({
                                   id = 0,
                                   entId = '',
                                   entName = '',
                                   date = new Date(),
                                   issueDate = new Date(),
                                   type = 'invoice',
                                   allDay = false,
                                   status = 'pending_approval',

                                   payments = [],
                                   receiptsIds = [],
                                   deliveryCertificatesIds = [],
                                   products = [],
                                   agent = '',

                                   mainValue = 0,
                                   preValue = undefined,
                                   taxValue = undefined,
                                   discount = undefined,
                                   round = undefined,
                                   round_tax = undefined,
                                   hand = undefined,
                                   hand_tax = undefined,
                                   hand_text = undefined,
                                   details,
                                   comment = '',
                                   bul = undefined,
                                   category = undefined,
                                   orderStatus = undefined,
                                   sign = undefined,
                                   photo = undefined,
                                   refundRest = undefined,
                                   entBName = '',
                                   handArray = undefined,
                                    notes2= undefined,
                                    notes3= undefined,
    notes4=undefined,
    notes5=undefined,
    agent_id=undefined,

    history,
                                   taxConfirmation = undefined,
                                   nkObject = null
                               }: {
    id?: number;
    entId?: string;
    entName?: string;
    date?: Date;
    issueDate?: Date;
    type?: DocumentType;
    allDay?: boolean;
    status?: DocumentStatus;
    payments?: Payment[];
    receiptsIds?: number[];
    deliveryCertificatesIds?: number[];
    products?: IItem[];
    agent?: string;
    discount?: number;
    details?: Details;
    comment: string;
    mainValue: number
    taxValue?: number
    preValue?: number
    round?: number
    round_tax?: number
    hand?: number
    hand_tax?: number
    hand_text?: string
    bul?: number
    category?: string
    orderStatus?: string
    handArray?: HandLine[]
    sign?: boolean
    photo?: boolean
    entBName?: string
    refundRest?: boolean
    notes2?: string
    notes3?: string
    notes4?: string
    notes5?: string
    agent_id?: number
    history?: any
    taxConfirmation?:string
    nkObject?: any;
}): ExtendedDocument => {
    let doc;
    if (type === 'delivery_certificate' || type === 'supplier_note') {
        doc = {
            products,
            taxValue,
            preValue,
            bul,
            comment,
            returnsOnly: nkObject?.isOnlyReturns(),
        };

        doc.pdf = nkObject?.pdf === 1;

    } else if (type === 'tax_invoice' || type === 'refund' || type === 'invoice') {
        doc = {
            products,
            deliveryCertificatesIds,
            round,
            round_tax,
            hand,
            hand_tax,
            discount,
            payments,
            taxValue,
            preValue,
            comment,
            details,
            hand_text,
            handArray
        };
    } else if (type === 'receipt') {
        doc = {
            receiptsIds,
            payments,
            comment,
            details,
            refundRest: refundRest
        };
    } else if (type === 'order') {
        doc = {
            products,
            taxValue,
            preValue,
            category,
            comment,
            orderStatus,
        };
        doc.driver = nkObject?.driver_id ? nkObject?.getDriver()?.user_name : undefined;
        doc.ref_id = nkObject?.ref_id;
    } else if (type === 'debt') {
        doc = {   comment};
    } else if (type === 'visit_note') {
        const product = nkObject?.getConnectedProduct()
        doc = {
            comment,
            payment:nkObject?.getConnectedPayment(),
            product: nkObject?.getAmounts() ? {id:product?.id,name:product?.name,amount:roundDecimals((nkObject?.getAmounts())),price:roundDecimals((nkObject?.getConnectedValue()/nkObject?.getAmounts())),total:roundDecimals((nkObject?.getConnectedValue()))} :undefined

        };
        doc.states = nkObject?.getStatesStr()?.toArray() ?? [];
    }
    doc.id = id;
    doc.entId = entId;
    doc.date = date;
    doc.type = type;
    doc.end = date ? addHours(parseJSON(date), 1) : new Date();
    doc.issueDate = issueDate;
    doc.status = status;
    doc.agent = agent;
    doc.entName = entName;
    doc.entBName = entBName;
    doc.mainValue = mainValue;
    doc.sign = sign;
    doc.photo = photo;
    doc.nkObject = nkObject;
    doc.totalAmount = nkObject?.getAmounts()
    doc.taxConfirmation=taxConfirmation
    doc.driver = nkObject?.driver_id ? nkObject?.getDriver()?.user_name : undefined;
    // enrich
    doc.allDay = allDay;
    doc.title = `${documentTypes[type].name} ${id.toString()}`;
    doc.name = documentTypes[type].name;
    doc.description = documentTypes[type].name;
    doc.color = documentTypes[type].color;
    doc.textColor = documentTypes[type].textColor;
    doc.baseColor = documentTypes[type].baseColor;
    doc.notes2=notes2 ?? nkObject?.notes2
    doc.notes3=notes3 ?? nkObject?.notes3
    doc.notes4=notes4 ?? nkObject?.notes4
    doc.notes5=notes5 ?? nkObject?.notes5
    doc.agent_id=agent_id ?? nkObject?.agent_id
    doc.orderId = nkObject?.order_id;
    doc.history =history;



    doc.notvalid = (doc as ProductNote)?.products?.some((p) => ((p.quantity > 0 || p.quantityReturns > 0) && p.totalPrice == 0)) ?? false;
    doc.storeNext = doc?.nkObject?.getStoreNext();
    doc.manager = doc?.nkObject?.getManager();
    doc.hashavshevet = doc?.nkObject?.getHashavshevet();
    doc.one = doc?.nkObject?.getOne();

    return doc;


};


export const deserializeTaxNote = (n: any): ExtendedDocument => {
    let status: DocumentStatus;
    if (!n.isUsed() && n.isActive()) {
        status = 'open';
    } else if (!n.isActive()) {
        status = 'canceled';
    } else if (n.isActive() && n.isUsed()) {
        status = 'closed';
    } else {
        status = 'approved';
    }
    const client = JSDB().getClient(n.client_id).first;
    let payments = [];
    if (n.paymentsData.size > 0) {
        payments = n.paymentsData.toArray().map((p, index) => serializePayment(p, index));
    }
    let products: IItem[] = [];

    if (n.productDeliveries.size > 0) {
        products = itemsApi(client, n.getConnectedDate(), JSDB().TaxNoteAssignRealPrice(n));
    }
    const hand = n.getHandValueOrNull();
    let handValue = 0;

    if (hand != null) {
        handValue = hand.value;
        const handTax = hand.taxPaid;
        if (client.getIncludeTax() === 0) {
            handValue -= handTax;
        }
    }
    const handArrayTemp = n.getFreeValueLinesOrNull()
    let handArray: HandLine[] = undefined
    if (handArrayTemp.size > 0) {
        handArray = handArrayTemp.toArray().map(line => {
            const value = line.value / (line.amount ?? 1)
            const tax = (line.taxPaid ?? 0) / (line.amount ?? 1)
            return {
                value: client.getIncludeTax() == 1 ? value : value - tax,
                tax: tax,
                description: line.details,
                amount: line.amount ?? 1,
            }

        });
    }
    const round = n.getRoundValueOrNull();
    let roundValue = 0;
    if (round != null) {
        roundValue = round.value;
        const roundTax = round.taxPaid;
        if (client.getIncludeTax() === 0) {
            roundValue -= roundTax;
        }
    }
    if (n.size > 0) {
        products = itemsApi(client, n.getConnectedDate(), JSDB().TaxNoteAssignRealPrice(n));
    }
    let deliveryIds: number[] = [];
    if (n.hasNotes()) {
        deliveryIds = n.getNotes().toArray();
    }
    let type: DocumentType = 'invoice';
    if (n.taxNoteType.name === 'WITH_PAYMENT')
        type = 'tax_invoice';
    else if (n.taxNoteType.name === 'CANCEL')
        type = 'refund';
    return createDocument(
        {
            mainValue: roundDecimals(n.total_value, 3),
            preValue: roundDecimals(n.value, 3),
            taxValue: roundDecimals(n.getTaxPaid(), 3),
            comment: n.external_details,
            handArray: handArray,
            entName: client.getName(n.getConnectedDate()),
            entBName: client.getBusinessName(n.getConnectedDate()),
            id: n.getConnectedId(),
            entId: n.getConnectedEntId().toString(),
            date: StringToDateObjectAddTime(n.getConnectedDate(), n.date),
            issueDate: StringToDateTimeObject(n.date),
            type,
            allDay: false,
            status,
            details: serializeDetails(n),
            payments,
            hand: handValue,
            round: roundValue,
            receiptsIds: [],
            deliveryCertificatesIds: deliveryIds,
            products,
            agent: n.agent,
            discount: n.discount_percent,
            sign: n.digiSign(),
            photo: n.digiPhoto(),
            taxConfirmation: n.taxConfirmation,
            nkObject: n
        }
    );
};
export const deserializePayNote = (n: any): ExtendedDocument => {
    let status: DocumentStatus;
    if (!n.isUsed() && n.isActive()) {
        status = 'open';
    } else if (!n.isActive()) {
        status = 'canceled';
    } else if (n.isActive() && n.isUsed()) {
        status = 'closed';
    } else {
        status = 'approved';
    }
    const client = JSDB().getClient(n.client_id).first;
    const date =StringToDateObjectAddTime(n.getConnectedDate(), n.date)
    const payments = n.paymentsData.toArray().map((p, index) => serializePayment(p, index,date));

    let taxNotes = [];
    if (n.taxNotes.size > 0) {
        taxNotes = n.taxNotes.toArray();
    }


    return createDocument(
        {
            mainValue: roundDecimals(n.value, 3), preValue: 0, taxValue: 0,
            comment: n.external_details,
            entName: client.getName(n.getConnectedDate()),
            entBName: client.getBusinessName(n.getConnectedDate()),
            id: n.getConnectedId(),
            entId: n.getConnectedEntId().toString(),
            date: date,
            issueDate: StringToDateTimeObject(n.date),
            type: 'receipt',
            allDay: false,
            details: serializeDetails(n),
            status,
            payments,
            receiptsIds: taxNotes,
            deliveryCertificatesIds: [],
            products: [],
            agent: n.agent,
            sign: n.digiSign(),
            photo: n.digiPhoto(),
            nkObject: n
        }
    );
};
export const toArrayOrEmpty = (n: any): [] => {
    return (n.size == 0) ? [] : n.toArray()
};
export const deserializeDebt = (n: any): ExtendedDocument => {
    let status: DocumentStatus;
    if (!n.isUsed() && n.isActive()) {
        status = 'open';
    } else if (!n.isActive()) {
        status = 'canceled';
    } else if (n.isActive() && n.isUsed()) {
        status = 'closed';
    } else {
        status = 'approved';
    }
    const client = JSDB().getClient(n.getConnectedEntId()).first;
    return createDocument(
        {
            comment: n.notes,
            mainValue: roundDecimals(n.getConnectedValue(), 3), preValue: 0, taxValue: 0,
            entName: client.getName(n.getConnectedDate()),
            entBName: client.getBusinessName(n.getConnectedDate()),
            id: n.getConnectedId(),
            entId: n.getConnectedEntId().toString(),
            date: StringToDateObjectAddTime(n.getConnectedDate(), n.date),
            issueDate: StringToDateTimeObject(n.getConnectedDateTime()),
            type: 'debt',
            allDay: false,
            status,

            agent: n.getConnectedAgent(),
            nkObject: n
        }
    );
};
export const deserializeVisitNote = (n: any): ExtendedDocument => {
    let status: DocumentStatus;
    if (n.isUsed()) {
        status = 'closed';

    } else if (n.isActive()) {
        status = 'open';
    } else {
        status = 'canceled';
    }
    const client = JSDB().getClient(n.getConnectedEntId()).first;
    return createDocument(
        {
            mainValue: roundDecimals(n.getConnectedValue(), 3), preValue: 0, taxValue: 0,
            entName: client.getName(n.getConnectedDate()),
            entBName: client.getBusinessName(n.getConnectedDate()),
            id: n.getConnectedId(),
            entId: n.getConnectedEntId().toString(),
            date: StringToDateObjectAddTime(n.getConnectedDate(), n.date_issued),
            issueDate: StringToDateTimeObject(n.date_issued),
            type: 'visit_note',
            allDay: false,
            status,
            agent: n.getConnectedAgent(),
            comment: n.comment,
            photo: n.digiPhoto(),
            nkObject: n
        }
    );
};
export const deserializeDeliveryNote = (n: any): ExtendedDocument => {
    let status: DocumentStatus;
    if (!n.isUsed() && n.isActive()) {
        status = 'open';
    } else if (!n.isActive()) {
        status = 'canceled';
    } else if (n.isActive() && n.isUsed()) {
        status = 'closed';
    } else {
        status = 'approved';
    }
    const isClient = n.getConnectedDocType() != 6
    const client = isClient ? JSDB().getClient(n.ent_id).first : JSDB().getSupplier(n.ent_id).first;


    let type: DocumentType = 'delivery_certificate';
    let orderStatus = undefined;
    if (n.getConnectedDocType() === 10) {
        type = 'order';
        orderStatus = n.orderStatus.orderName();
    } else if (n.getConnectedDocType() === 6) {
        type = 'supplier_note'
    }
    let history: IItem[]|undefined = undefined;
    if(n.histroy_info){
        history= itemsApi(client, n.getConnectedDate(), (n.histroy_info))
    }
    const x = createDocument(
        {
            mainValue: roundDecimals(n.value, 3),
            preValue: roundDecimals(n.value - n.taxPaid, 3),
            taxValue: roundDecimals(n.taxPaid, 3),
            id: n.getConnectedId(),
            entId: n.getConnectedEntId().toString(),
            entName: client?.getName(n.getConnectedDate()) ?? '',
            entBName: client?.getBusinessName(n.getConnectedDate())?? '',
            date: StringToDateObjectAddTime(n.getConnectedDate(), n.date_issued),
            issueDate: StringToDateTimeObject(n.date_issued),
            type,
            allDay: false,
            status,
            comment: n.notes,
            category: n.category,
            bul: Number(n.connected_id),
            payments: [],
            receiptsIds: [],
            deliveryCertificatesIds: [],
            products: itemsApi(client, n.getConnectedDate(), client ? JSDB().deliveryNoteAssignRealPrice(isClient, n): n.delivery_info),
            history: history,
            agent: n.agent,
            orderStatus: orderStatus,
            sign: n.digiSign(),
            notes2: n.notes2,
            notes3: n.notes3,
            photo: n.digiPhoto(),
            nkObject: n
        }
    );

    return x
};
export const isNoteCompare = (products1:IItem[],products2:IItem[])=>{

    if(products1.length!=products2.length){
        return false
    }
    const pd = {}
    products2.forEach(p=>{
        pd[p.id]=p
    })

    for(let i=0;i<products1.length;i++){

        if(!(products1[i].id in pd) || products1[i].quantity!=pd[products1[i].id].quantity ){

            return false
        }
    }
    return true

}
export const deserializeDocument = (d: any): ExtendedDocument | null => {

    const nkDocType: number = d.getConnectedDocType();
    let doc: ExtendedDocument;
    if (nkDocType === 4 || nkDocType === 10 || nkDocType === 11 || nkDocType === 6) {
        doc = deserializeDeliveryNote(d);
    } else if (nkDocType === 0 || nkDocType === 1 || nkDocType === 2) {
        doc = deserializeTaxNote(d);
    } else if (nkDocType === 7) {
        doc = deserializePayNote(d);
    } else if (nkDocType === 9) {
        doc = deserializeDebt(d);
    } else if (nkDocType === 12) {
        doc = deserializeVisitNote(d);
    } else {
        return null;
    }

    return doc;
};


export const deserializeCarteset = (c: ExtendedDocument): CartesetDataHolder => {
    let value_tn = 0;
    let value_pay = 0;
    let yearly = 0
    if (c.type === 'receipt') {
        value_pay = c.mainValue;
    }
    if (c.type === 'tax_invoice') {
        value_tn = c.mainValue;
        value_pay = c.mainValue;
    }
    if (c.type === 'invoice') {
        value_tn = c.mainValue;
    }
    if (c.type === 'refund') {
        value_tn = -c.mainValue;
    }
    if (c.type === 'debt') {
        value_tn = c.nkObject.positive;
        value_pay = c.nkObject.negative;
        yearly = c.nkObject.state
    }
    return {
        doc: c,
        id: c.id,
        name: c.name,
        agent: c.agent,
        status: c?.status ?? 'closed',
        date: c.date,
        dateIssue: c.issueDate,
        amount_tn: 0,
        value_tn,
        open_tn: 0,
        amount_pay: 0,
        value_pay,
        debt: 0,
        cname: c.entName,
        comment: c.comment,
        yearly: yearly
    };

};

export const distinctValuesCreators = (d: any, keys, queryFunc?): string[] => {

    const x = new Set<String>()
    if (d.values == 0)
        return [];

    keys.forEach((k) => {
        const arr: string[] = d[k];
        if (arr)
            arr.forEach((r) => x.add(r.trim()))
    });


    return Array.from(x);
};
