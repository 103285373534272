import React, { memo } from 'react';
import { CellProps } from 'react-datasheet-grid';
import { Avatar, Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { DataSheetGridContext } from '../index2';
import { IconDeviceFloppy, IconRotate2 } from '@tabler/icons';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BaseOrDataSelector from '../custom-components/BaseOrDataSelector';
import { callbackNetworkResult } from '../../../../api/customer/files.api';
import { getCofcLink } from '../../../../api/customer/documents.api';
import { whatsapp } from '../../../../assets/images';
import JSDB from '../../../../fromKotlin/nk';
import { dateObjectToString, dateObjectToStringIsrael } from '../../../../fromKotlin/nkutils';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { useTheme } from '@mui/material/styles';
import {orderKey} from "../../../../types/OrderData";

const ActionCell = React.memo(({
    rowData,
    rowIndex,
    params
}: any
    //CellProps<string | null>
) => {
    const {
        setSingleDelete,
        callPrevOrder,
        date,
        saveOrder,
        updatedRowIds,
        updatedDriverRowIds,
        callPrevDaily,
        deleteOrder,
        dvs_flag,
        setAll,
        openDrawer,
        setOpenDrawer,
        setDrawerSingleData,
    } = React.useContext(DataSheetGridContext);
    const theme = useTheme();
    const whatsappCallBack = (result) => {
        const client = JSDB().getClient(Number(rowData.id)).first
        let text = JSDB().userCofcConf?.text && JSDB().userCofcConf?.text != "None" ? JSDB().userCofcConf?.text : `שלום ${"#name"} ${"#id"}אנא בצע הזמנה עבור ${JSDB().getUser().first.user_company_print_name}`
        text = text.replace("#name", client.getName())
        text = text.replace("#id", client.id)
        text = text.replace("#external_id", client.getExternalId())
        text = text.replace("#date", dateObjectToStringIsrael(date))
        text = text.replace("#day", JSDB().getDatesManipulator().getDayOfWeekStr(dateObjectToString(date), true))

        const url = `${text.replaceAll('\\\\n', '%0a')} %0a %0a https://nihulkav.shop?token=${result}&date=${dateObjectToString(date)} %0a`;
        // console.log(encodeURIComponent("🙏🏽"))
        const phone = client.getPhone();
        const x=window.open(`https://api.whatsapp.com/send?text=${url}&phone=${JSDB().getValidPhone(phone)}`, '_blank');
        if (x) {
            x.focus();
        }
    };

    const [loading, setLoading] = React.useState<boolean>(undefined);
    React.useEffect(() => {
        if (loading != undefined && !loading) {
            saveOrder([orderKey(rowData)])
            setLoading(undefined);
        }
    }, [loading]);
    return (
        <Box sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row-reverse',
            alignItems: 'center'
        }}>
            <Tooltip title='מחק הזמנה'>
                <span>
                    <IconButton disabled={rowData.readonly || rowData.orderId == undefined || rowData.orderId == '' || rowData.status == 'אושרה ליצור' || rowData.status == 'בוצעה'}
                        style={{ color: (rowData.readonly || rowData.orderId == undefined || rowData.orderId == '') ? 'gray' : 'red' }}
                        size='medium' onClick={(e) => {
                            Swal.fire({
                                title: 'האם תרצה למחוק הזמנה?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: theme.palette.primary.main,
                                cancelButtonColor: theme.palette.error.main,
                                confirmButtonText: 'מחק',
                                cancelButtonText: 'ביטול פעולה'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    /* Perform insert, update, and delete to the database here */
                                    const node = params?.api?.getRowNode(orderKey(rowData));
                                    const newData = {
                                        ...rowData
                                    }
                                    if (newData?.hasBeenEdited) {
                                        delete newData?.hasBeenEdited;
                                    }
                                    if (newData?.hasBeenEditedFromStart) {
                                        delete newData?.hasBeenEditedFromStart;
                                    }
                                    params?.api?.applyTransaction({ update: [newData] });
                                    params?.api?.refreshCells({ rowNodes: [node], force: true });
                                    deleteOrder([rowData?.orderId]);
                                }
                            });

                        }}>
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip>

            <Tooltip title='בטל שינויים בהזמנה'>
                <span>
                    <IconButton
                        disabled={rowData?.readonly || (!updatedRowIds.has(orderKey(rowData)) && !updatedDriverRowIds.has(rowData?.id))}
                        style={{ color: 'orange' }} size='medium' onClick={(e) => {
                            const node = params?.api?.getRowNode(orderKey(rowData));
                            const newData = {
                                ...rowData
                            }
                            if (newData?.hasBeenEdited) {
                                delete newData?.hasBeenEdited;
                            }
                            if (newData?.hasBeenEditedFromStart) {
                                delete newData?.hasBeenEditedFromStart;
                            }
                            params?.api?.applyTransaction({ update: [newData] });
                            params?.api?.refreshCells({ rowNodes: [node], force: true });
                            setSingleDelete([orderKey(rowData)]);
                        }}>
                        <IconRotate2 />
                    </IconButton>
                </span>
            </Tooltip>

            <Tooltip title='שמור'>
                <span>
                    {loading ? <CircularProgress size={16} /> :
                        <IconButton size='small' disabled={dvs_flag || rowData?.readonly || (!updatedRowIds.has(orderKey(rowData)) && !updatedDriverRowIds.has(rowData?.id))}
                            style={{ color: 'blue', cursor: 'pointer' }}
                            onClick={(e) => {
                                setLoading(true)
                                setTimeout(() => {
                                    setLoading(false)



                                }, 1000);
                            }}
                        >
                            <IconDeviceFloppy style={{ cursor: 'pointer !important' }} />
                        </IconButton>
                    }
                </span>
            </Tooltip>
            <BaseOrDataSelector disabled={rowData?.readonly} callPrevDaily={callPrevDaily}
                callPrevOrder={callPrevOrder} ids={[rowData?.id]}
                date={date} />
            <Tooltip title='שלח בווטסאפ לביצוע הזמנה'>
                <IconButton size='small' onClick={(e) => {
                    callbackNetworkResult(() => getCofcLink(Number(rowData?.id), date), whatsappCallBack);

                }}>
                    <Avatar variant='square'
                        sx={{ cursor: 'pointer', bgcolor: 'white', width: '18px', height: '18px' }}
                        src={whatsapp} />
                </IconButton>
            </Tooltip>
            <Tooltip title='פתח חלון הזמנה'>
                <IconButton style={{ color: 'green' }} size='small' onClick={(e) => {
                    setAll(false)
                    // setActiveRow(rowIndex)
                    setOpenDrawer(!openDrawer)
                    setDrawerSingleData([rowData])
                    // handleOpenDrawer();
                }}>
                    <OpenInNewIcon fontSize='small' />
                </IconButton>
            </Tooltip>
        </Box >
    );
});

export default memo(ActionCell);
