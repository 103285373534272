import React, {memo, MutableRefObject, useCallback, useEffect, useMemo, useState} from 'react';
import "./index2.scss";
import {
    Autocomplete, Avatar, Box,
    Checkbox,
    Chip, Collapse,
    Dialog,
    IconButton,
    MenuItem, TableContainer,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';

import Swal from 'sweetalert2';
import InsertCommentIcon from '@mui/icons-material/InsertComment';

// types
import {Filter, HeadCell} from 'types';


// project imports
import ActionCell from './custom-cells/ActionCell';

// assets
import Product from '../../../types/product';
import {getSumProducts, OrderData, orderKey, orderKeyRef} from '../../../types/OrderData';
import {useDispatch, useSelector} from 'react-redux';
import {OrderActiveState} from '../../../types/OrderActiveState';

import AddCustomer from '../../customer/AddCustomer';
import {CalendarMonth, Email, Person, WhatsApp} from '@mui/icons-material';
import JSDB from '../../../fromKotlin/nk';
import {IconInfoCircle, IconPhone} from "@tabler/icons";
import {getCustomers, selectCustomersObjs} from "../../../store/customers/customersSlice";
import {Bucket, DeviceFloppy, FileCheck, MapPin, Package} from "tabler-icons-react";
import AgTable, {ITableControls} from "../../../ui-component/ag-grid/Ag-Table";
import {cloneDeep} from "lodash";
import contextMenu2 from './contextMenu2';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RouteIcon from '@mui/icons-material/Route';
import {
    autoSizeAllColumns,
    customGroup,
    customGroupLabelForChip,
    rowGroupRenderer
} from 'ui-component/ag-grid/ag-table-utils';
import {addDragToTable, removeDragFromTable} from 'ui-component/ag-grid/ag-grid-functions';
import {groupByUnStr} from "../../../api/customer/analytics.api";
import FormDialog from "../../orders/datasheet/custom-components/distributeDialog";
import {columns} from "../../../store/kanban";
import {dateObjectToString, roundDecimals} from "../../../fromKotlin/nkutils";
import LimitedActionCell from './custom-cells/LimitedActionCell';

import SendMessageDialog from "../../../ui-component/dialogs/sendMessage";
import ChipRangeDate from "./ChipRangeDate";
import ReorderDialog, {ReorderDialogOrders} from "../../../ui-component/ag-grid/components/reorder-dialog";
import ChooseDateMultiAction from "../../../ui-component/dialogs/chooseDateMultiAction";
import {useOpenOrders, useOpenOrders2} from "../../../hooks/customer/documents.hooks";
import ProductsToExportDialog from 'ui-component/dialogs/productsToExportDialog';
import {
    buildClientCartesetDebt,
    buildNotesSum,
    docResultsDeserialize,
    getMassDocumentPdf, sendPdfEmail
} from "../../../api/customer/documents.api";
import {updateMassCustomerProduct} from 'store/customersProducts/customersProductsSlice';
import BaseOrDataSelector from './custom-components/BaseOrDataSelector';
import DocumentPreview from "../../customer/profile/documents/DocumentPreview";
import MyChip from "../../../ui-component/extended/Chip";
import {deserializeDocument, isNoteCompare} from "../../../types/DocumentsTypes/utils";
import DocumentsTable from "../../customer/profile/documents/Table";
import GenerativeLoader from "../../../ui-component/GenerativeLoader";
import {getFile} from "../../../api/customer/files.api";
import FunctionsIcon from "@mui/icons-material/Functions";
import {pdf} from "../../../assets/images";
import {FormattedMessage} from "react-intl";
import PrintIcon from "@mui/icons-material/PrintTwoTone";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ArticleIcon from '@mui/icons-material/Article';
import VisibilityIcon from '@mui/icons-material/Visibility';
// <div style={{
//     display: 'flex',
//     justifyContent: 'center',
//
//     alignItems: 'center',
//
//     flexWrap: 'wrap'
//
// }}>
//
//     {openDrawer ? <AddCustomer
//         open={openDrawer}
//         handleDrawerOpen={handleDrawerClose}
//         id={rowData.id}
//         editable={true}
//     /> : undefined}
//
//     <Tooltip title='חולק היום'>
//         <Checkbox disabled checked={rowData.readonly}
//                   style={{ 'color': 'green', paddingInline: 0 }} size='small' />
//     </Tooltip>
//     <Tooltip title='הוזמן היום'>
//         <Checkbox disabled checked={rowData.orderId !== ''}
//                   style={{ 'color': 'red', paddingInline: 2 }} size='small' />
//     </Tooltip>
//     <IconButton
//         style={{ color: rowData.notes && rowData.notes != '' ? 'orange':undefined }} size='medium' onClick={(e) => {
//
//
//         Swal.fire({
//             title: 'הערות',
//             input: !rowData.readonly ? 'textarea':undefined,
//             inputValue:rowData.notes ?? "",
//             inputAttributes: {
//
//                 'aria-label': rowData.notes ?? ""
//             },
//             showCancelButton: true,
//             showConfirmButton: !rowData.readonly,
//             text: rowData.readonly ? rowData.notes:undefined,
//             showClass: {
//                 popup: 'animate__animated animate__fadeInDown'
//             },
//             hideClass: {
//                 popup: 'animate__animated animate__fadeOutUp'
//             }
//             , confirmButtonText: 'צור הערה'
//             , cancelButtonText: 'סגור'
//         }) .then((result) => {
//             /* Read more about handling dismissals below */
//
//             if(result.isConfirmed && !rowData.readonly) {
//                 updatedRowIds.add(rowData.id)
//                 setData((prev) =>
//                     prev.map((d, i) =>
//                         d.id! == rowData.id
//                             ? {...d,  notes:String(result.value),me:d} : d
//                     )
//                 );
//
//             }
//         });
//     }}>


export const DataSheetGridContext = React.createContext({
    activeRow: 0,
    openDrawer: false,
    setOpenDrawer: (open: boolean) => {
    },
    handleOpenDrawer: () => {
    },
    setSingleSave: (rowid) => {

    },
    setSingleDelete: (rowid) => {

    },
    callPrevOrder: (callDate: Date, ids?: number[], day?: number) => {

    },
    setDrawerSingleData: (data: any) => {
    },
    date: new Date(),
    unsavedRowsLength: 0,
    current: null,
    markedIds: new Set(),
    updatedRowIds: null,
    updatedDriverRowIds: null,
    data: null,
    setData: null,
    columns: null,
    disableKeyBoard: null,
    saveOrder: null,
    callPrevDaily: null,
    deleteOrder: null,
    dvs_flag: false,
    activeState: null,
    onApplyFilter: null,
    addCustomerFilter: null,
    removeAllFilters: null,
    setAll: null,
    setActiveRow: null,
});

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}


export const simulateClick = (className) => {
    const clickEvent = document.createEvent('MouseEvents');
    clickEvent.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0,
        false, false, false, false, 0, null);

    const element = document.getElementsByClassName(className).item(0);
    element?.dispatchEvent(clickEvent);
};
const headCells: HeadCell[] = [
    {
        id: 'checkbox',
        numeric: false,
        label: '',
        align: 'center',
        type: 'string',
    },
    {
        id: 'actions',
        numeric: false,
        label: 'פעולות',
        align: 'center',
        filterable: false,
        sortable: false,
        pinned: "left",
        cellRenderer: ({params}) => {
            if ((params?.node?.rowPinned || params?.node?.group)) return '';
            return <ActionCell rowData={params?.data} rowIndex={params?.rowIndex} params={params}/>
        },
        minWidth: 250,
    },
    {
        id: "limited-actions",
        numeric: false,
        label: 'פעולה',
        align: 'center',
        filterable: false,
        sortable: false,
        pinned: "left",
        hide: true,
        cellRenderer: ({params}) => {
            if ((params?.node?.rowPinned || params?.node?.group)) return '';
            return <LimitedActionCell rowData={params?.data} rowIndex={params?.rowIndex} params={params}/>
        },
        minWidth: 100,
        maxWidth: 100,
    },
    {
        id: 'id',
        numeric: true,
        type: 'number',
        label: 'מזהה',
        align: 'center',
        maxWidth: 100,
        pinned: "right",
        extractFunc: (rowData) => Number(rowData.id) ?? '',
    },
    {
        id: 'customer',
        numeric: false,
        label: 'שם לקוח',
        align: 'center',
        pinned: "right",
        cellRenderer({params, setOpenCustomerDrawer, setSelectedCustomerId}) {
            if (params?.node?.group) return params?.value ?? '';
            if (params?.node?.rowPinned) return params?.value ?? '';
            if(!(params?.value) || params?.value=='') return '';
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    // flexWrap: 'wrap',
                    width: '100%',
                    overflow: 'hidden',
                }}>
                    <Tooltip title='פרטי לקוח' style={{color: 'blue', marginRight: 0, marginLeft: 0, paddingInline: 0}}>
                        <>
                            <IconButton
                                style={{
                                    color: 'blue',
                                    marginRight: 0,
                                    marginLeft: 0,
                                    paddingInline: 0,
                                    display: "flex",
                                    flexDirection: "column"
                                }} size='medium'
                                onClick={() => {
                                    setSelectedCustomerId(params?.data);
                                    setOpenCustomerDrawer(true);
                                }}>
                                <Person/>
                            </IconButton>
                            <span style={{
                                fontSize: "0.8rem"
                            }}>
                                {params?.value ?? ''}
                            </span>
                        </>
                    </Tooltip>
                </div>
            )
        },
    },
    {
        id: "customerActions",
        label: "פעולות לקוח",
        numeric: false,
        maxWidth: 120,
        aggFunc: 'count',
        minWidth: 120,
        pinned: "right",
        extractFunc: (rowData) => {
            return rowData?.dvs?.length ?? 0 > 0
        },
        cellRenderer({params, updatedRowIds, setTempData}) {
            if (params?.node?.group) return '';
            if (params?.node?.rowPinned) {
                return (
                    <div style={{
                        display: 'flex',
                        alignItems: "center",
                    }}>
                        {params?.node?.data?.customerActions ? rowGroupRenderer(params?.node?.data?.customerActions || "") : ''}
                        <FileCheck style={{
                            color: "green",
                            marginRight: "5px"
                        }}/>
                    </div>
                )
            }
            const rowData = params?.data ?? {};
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '100%',
                    overflow: 'hidden',
                    maxWidth: 120
                }}>
                    <Tooltip title='חולק היום'>
                        <Checkbox disabled checked={params?.data?.readonly}
                                  style={{'color': 'green', paddingInline: 0}} size='small'/>
                    </Tooltip>

                    <Tooltip title='הוזמן היום'>
                        <Checkbox disabled checked={params?.data?.orderId !== ''}
                                  style={{'color': 'red', paddingInline: 2}} size='small'/>
                    </Tooltip>

                    <IconButton
                        style={{color: rowData.notes && rowData.notes != '' ? 'orange' : undefined}} size='medium'
                        onClick={(e) => {


                            Swal.fire({
                                title: 'הערות',
                                input: !rowData?.readonly ? 'textarea' : undefined,
                                inputValue: rowData?.notes ?? "",
                                inputAttributes: {

                                    'aria-label': rowData?.notes ?? ""
                                },
                                showCancelButton: true,
                                showConfirmButton: !rowData?.readonly,
                                text: rowData?.readonly ? rowData?.notes : undefined,
                                showClass: {
                                    popup: 'animate__animated animate__fadeInDown'
                                },
                                hideClass: {
                                    popup: 'animate__animated animate__fadeOutUp'
                                }
                                , confirmButtonText: 'צור הערה'
                                , cancelButtonText: 'סגור'
                            }).then((result) => {
                                /* Read more about handling dismissals below */

                                if (result?.isConfirmed && !rowData?.readonly) {
                                    updatedRowIds.add(orderKey(rowData))
                                    params.data.hasBeenEdited = true;
                                    params.api.refreshCells({force: true, rowNodes: [params.node]});
                                    setTempData((prev) =>
                                        prev.map((d, i) =>
                                            d.id! == rowData.id
                                                ? {...d, notes: String(result.value), me: d,} : d
                                        )
                                    );

                                }
                            });
                        }}>
                        <InsertCommentIcon/>
                    </IconButton>
                </div>
            )
        }
    },
    {
        id: 'orderId',
        numeric: true,
        label: 'מס הזמנה',
        hide: false,
        align: 'right',

        cellRenderer({params, setPreviewOpen}) {
            if (params?.node?.group || params?.node?.rowPinned) return '';
            return (<Box sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', '& .MuiButton-root': {
                    minWidth: "0px",
                }
            }}>
                <MyChip
                    sx={
                        {
                            marginTop: "8px",
                        }
                    }
                    label={params?.value}
                    onClick={() => {
                        if (params?.node?.data?.nkObject) {
                            setPreviewOpen(deserializeDocument(params?.node?.data?.nkObject));
                        }
                    }}
                    variant='outlined'
                    size='small'
                    chipcolor={params?.node?.data?.readonly ? 'success' : 'orange'}
                />
                 <Tooltip title={'פירוט תעודות'}>
                    <GenerativeLoader
                        disabled={!(params?.node?.data?.dvs && params?.node?.data?.dvs?.length>0)}
                        func={(setLoader) => {

                            setPreviewOpen(params?.node?.data?.dvs?.map((d)=>deserializeDocument(d?.nkObject)));

                        }} icon={<IconButton
                        aria-label='expand row'
                        size='small'

                    >
                        <ArticleIcon color={!(params?.node?.data?.dvs && params?.node?.data?.dvs?.length>0) ? 'action':'primary'}/>
                    </IconButton>}/>

                </Tooltip>



            </Box>)
        },

    },

    {
        id: 'collectorData',
        numeric: true,
        label: 'ליקוט',
        hide: true,
        align: 'center'
    },
    {
        id: 'karton',
        numeric: true,
        label: '~קרטונים',
        hide: true,
        align: 'center',
        aggFunc: 'sum',
        pinnedRowProps: {
            action: "sum",
        },
        cellRenderer: ({ params }) => {
            if (params?.node?.group) {
                return rowGroupRenderer(params?.value || "");
            }
            return params?.value || ""
        },
        priority: -1
    },
    {
        id: 'money',
        numeric: true,
        label: 'סכום',
        hide: true,
        align: 'center',
        aggFunc: 'sum',
        pinnedRowProps: {
            action: "sum",
        },
        extractFunc: (rowData) => {
            return rowData?.nkObject?.value ? roundDecimals(rowData?.nkObject?.value) : undefined

        },
        cellRenderer: ({ params }) => {
            if (params?.node?.group) {
                return rowGroupRenderer(params?.value || "");
            }
            return params?.value || ""
        },
        priority: -1
    },    {
        id: 'moneyBefore',
        numeric: true,
        label: 'סכום לפני',
        hide: true,
        align: 'center',
        aggFunc: 'sum',
        pinnedRowProps: {
            action: "sum",
        },
        extractFunc: (rowData) => {
            return rowData?.nkObject?.value ? roundDecimals(rowData?.nkObject?.value - rowData?.nkObject?.taxPaid) : undefined

        },
        cellRenderer: ({ params }) => {
            if (params?.node?.group) {
                return rowGroupRenderer(params?.value || "");
            }
            return params?.value || ""
        },
        priority: -1
    },
    {
        id: 'totalProducts',
        numeric: true,
        label: 'סכום מוצרים',
        hide: true,
        align: 'center',
        aggFunc: 'sum',
        pinnedRowProps: {
            action: "sum",
        },
        cellRenderer: ({ params }) => {
            if (params?.node?.group) {
                return rowGroupRenderer(params?.value || "");
            }
            return params?.value || ""
        },
        editable: false,
        extractFunc: (rowData) => {
            return rowData?.nkObject?.amount_pos ? roundDecimals(rowData?.nkObject?.amount_pos) : 0

        },
        priority: -1
    },
    {
        id: 'status',
        numeric: false,
        label: 'סטטוס',
        align: 'center',
        editable: (row) => {
            return !row?.node?.data?.readonly
        },
        cellRenderer: ({params}) => {
            if (params?.node?.rowPinned) {
                return (
                    <div style={{
                        display: 'flex',
                        alignItems: "center",
                    }}>
                        {params?.value && params?.value != 0 ? params?.value : ''}
                    </div>
                )
            }
            return params?.value ?? ""; //<CustomerStatus customer={params?.data} />
        },
        enableRowGroup: true
    },
    {
        id: 'category',
        numeric: false,
        type: "string",
        type:'numOrStr',
        label: 'קטגוריה',
        align: 'center',
        pinnedRowProps: {
            action: "uniqueCount"
        },
        editable: (row) => {
            return !row?.node?.data?.readonly
        },
        enableRowGroup: true
    },
    {
        id: "location",
        label: "GPS",
        hide: true,
        numeric: false,
        priority: -1,
        cellRenderer: ({params}) => {
            if ((params?.node?.rowPinned || params?.node?.group)) return '';
            return (
                <div style={{
                    display: 'flex',
                    alignItems: "center",
                }}
                >
                    {params?.value != "" && <><MapPin size={14} strokeWidth={1} color="#000000"/>
                        <div><Typography variant="caption">{params?.value}</Typography></div>
                    </>}
                </div>
            )
        },
        extractFunc: (row) => {
            if (row.id) {
                const client = JSDB().getClient(Number(row.id)).first;
                return client?.location ?? '';
            }
            return ''

        },
    },
    {
        id: "last_created",
        label: "תאריך יצירה",
        numeric: false,
        hide: true,
        priority: -1,
    },
    {
        id: "last_updated",
        label: "תאריך עדכון",
        numeric: false,
        hide: true,
        priority: -1,
    },
    {
        id: "notes",
        label: "הערות",
        numeric: false,
        hide: true,
        priority: -1,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        editable: (row) => {
            return true
        },
    },


    {
        id: "perment_notes",
        label: "הערות קבועות",
        numeric: false,
        hide: true,
        priority: -1,
        extractFunc: (row) => {
            if (row.id) {
                const client = JSDB().getClient(Number(row.id)).first;
                return client?.comments ?? '';

            } else
                return ''

        },
    },
    {
        id: "phone",
        label: "טלפון",
        numeric: false,
        hide: true,
        priority: -1,
        cellRenderer: ({params}) => {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    {params?.value && <Typography variant="subtitle1">
                        <IconPhone size={12} strokeWidth={1} color='#000000'/> {params?.value}
                    </Typography>}
                </div>
            )
        },
        extractFunc: (row) => {
            if (row.id) {
                const client = JSDB().getClient(Number(row.id)).first;
                return client?.getPhone() ?? '';
            } else
                return ''
        },
    },
    {
        id: "phone2",
        label: "טלפון 2",
        numeric: false,
        priority: -1,
        hide: true,
        cellRenderer: ({params}) => {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    {params?.value && <Typography variant="subtitle1">
                        <IconPhone size={12} strokeWidth={1} color='#000000'/> {params?.value}
                    </Typography>}
                </div>
            )
        },
        extractFunc: (row) => {
            if (row.id) {
                const client = JSDB().getClient(Number(row.id)).first;
                return client?.getSecondPhone() ?? '';
            }
            return ''

        },
    },
    {
        id: "c_category",
        label: "קטגורית לקוחות",
        numeric: false,
        priority: -1,
        hide: true,
        type:'numOrStr',
        enableRowGroup: true
    },
    {
        id: "c_category2",
        label: "קבוצת לקוחות",
        numeric: false,
        priority: -1,
        type:'numOrStr',
        hide: true,
        extractFunc: (row) => {
            if (row.id) {
                const client = JSDB().getClient(Number(row.id)).first;
                return client?.category2 ?? '';
            }
            return ''

        },
        enableRowGroup: true
    },
    {
        id: "position",
        label: "סדר לקוחות",
        numeric: true,
        priority: -1,
        hide: true,

    },
    {
        id: "driver_position",
        label: "סדר נהגים",
        numeric: true,
        priority: -1,
        hide: true,
        editable: (row) => {
            return true
        }

    },
    {
        id: "collector_position",
        label: "סדר ליקוט",
        numeric: true,
        priority: -1,
        editable: (row) => {
            return true
        },
        hide: true,

    },
    {
        id: 'agent',
        numeric: false,
        label: 'שם קו',
        align: 'center',
        enableRowGroup: true,
        pinnedRowProps: {
            action: "uniqueCount"
        },
        editable: (row) => {
            return !row?.node?.data?.readonly
        },
        cellRenderer: ({params}) => {
            if (params?.node?.group) return params?.value || '';
            if (params?.node?.rowPinned) {
                return (
                    <span style={{
                        display: 'flex',
                        alignItems: "center",
                    }}>
                        {params?.value || ""} {params?.value ?
                        <RouteIcon style={{marginRight: "5px", color: "blue"}}/> : ""}
                    </span>
                )
            }
            return params?.value || '';
        }
    },
    {
        id: "car",
        label: "מס רכב",
        numeric: false,
        priority: -1,
        type: "string",
        hide: true,
        editable: (row) => {
            return !row?.node?.data?.readonly
        },
    },

    {
        id: 'driverName',
        numeric: false,
        label: 'נהג',
        align: 'center',
        enableRowGroup: true,
        pinnedRowProps: {
            action: "uniqueCount"
        },
        editable: (row) => {
            return true
        },
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            if (valueA == '') return -1
            if (valueB == '') return 1
            if (!valueA) return 1;
            if (!valueB) return -1;
            if (nodeA?.group || nodeB?.group) {
                return valueB.localeCompare(valueA);
            }
            if (valueA == valueB)
                return (nodeA?.data?.driver_position ?? 0) - (nodeB?.data?.driver_position ?? 0)
            return valueA.localeCompare(valueB)
        },
        cellRenderer: ({params}) => {
            if (params?.node?.group) return params?.value || '';
            if (params?.node?.rowPinned === "top") {
                return (
                    <span style={{
                        display: 'flex',
                        alignItems: "center",
                    }}>
                        {params?.value || ''} {params?.value ? <LocalShippingIcon style={{
                        marginRight: '5px',
                        color: '#00a5ff'
                    }}/> : undefined}
                    </span>
                )
            }
            return params?.value || '';
        },
    },
    {
        id: 'orderDriver',
        numeric: false,
        label: 'נהג זמני',
        align: 'center',
        hide: true,
        enableRowGroup: true,
        pinnedRowProps: {
            action: "uniqueCount"
        },
        editable: (row) => {
            return true
        },
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            if (valueA == '') return -1
            if (valueB == '') return 1
            if (!valueA) return -1;
            if (!valueB) return 1;
            if (nodeA?.group || nodeB?.group) {
                return valueB.localeCompare(valueA);
            }
            if (valueA == valueB)
                (nodeA?.driver_position ?? 0) - (nodeB?.driver_position ?? 0)
            return valueB.localeCompare(valueA)
        },
        cellRenderer: ({params}) => {
            if (params?.node?.group) return params?.value || '';
            if (params?.node?.rowPinned === "top") {
                return (
                    <span style={{
                        display: 'flex',
                        alignItems: "center",
                    }}>
                        {params?.value || ''} {params?.value ? <LocalShippingIcon style={{
                        marginRight: '5px',
                        color: '#00a5ff'
                    }}/> : undefined}
                    </span>
                )
            }
            return params?.value || '';
        },
    },
    {
        id: 'collectorName',
        numeric: false,
        label: 'מלקט',
        hide: true,
        align: 'center',
        enableRowGroup: true,
        pinnedRowProps: {
            action: "uniqueCount"
        },
        editable: (row) => {
            return true
        },
        cellRenderer: ({params}) => {
            if (params?.node?.group) return params?.value || '';
            if (params?.node?.rowPinned === "top") {
                return (
                    <span style={{
                        display: 'flex',
                        alignItems: "center",
                    }}>
                        {params?.value || ''} {params?.value ? <Bucket style={{
                        marginRight: '5px',
                        color: '#000000'
                    }}/> : undefined}
                    </span>
                )
            }
            return params?.value || '';
        }
    },
    {
        id: 'street',
        numeric: false,
        label: 'רחוב',
        align: 'center',
        extendedSearchProperties: ['city', 'street', 'streetNumber'],
        searchQueryPlaceholder: 'עיר/כתובת',
        enableRowGroup: true,
        pinnedRowProps: {
            action: "uniqueCount"
        },
        // extractFunc: (row) => {
        //     const address = row?.street && row?.streetNumber ? `${row?.street}, ${row?.streetNumber}` : row?.street;
        //     return address
        //
        // },
        extractFunc: (row) => {
            if (row.id) {
                const client = JSDB().getClient(Number(row.id)).first;
                const address = client?.street && client?.streetNumber ? `${client?.street}, ${client?.streetNumber}` : client?.street;
                const full_address = client?.city && address ? `${client?.city} ${address}` : client?.city;
                return full_address

            } else
                return ''

        },

    },
    {
        id: 'city',
        numeric: false,
        label: 'עיר',
        align: 'center',
        extendedSearchProperties: ['city', 'street', 'streetNumber'],
        searchQueryPlaceholder: 'עיר/כתובת',
        enableRowGroup: true,
        pinnedRowProps: {
            action: "uniqueCount"
        },
        // extractFunc: (row) => {
        //     const address = row?.street && row?.streetNumber ? `${row?.street}, ${row?.streetNumber}` : row?.street;
        //     return address
        //
        // },

        cellRenderer: ({params}) => {
            if (params?.node?.group) return params?.value || '';
            if (params?.node?.rowPinned === "top") {
                return (
                    <span style={{
                        display: 'flex',
                        alignItems: "center",
                    }}>
                        {params?.value || ''} {params?.value ? <LocationCityIcon style={{
                        marginRight: '5px',
                        color: "brown"
                    }}/> : ""}
                    </span>
                )
            }
            const address = params?.data?.street && params?.data?.streetNumber ? `${params?.data?.street}, ${params?.data?.streetNumber}` : params?.data?.street;
            // const full_address = params?.data?.city && address ? `${address} ${params?.data?.city}` : params?.data?.city;
            // const openGmaps = (result) => {
            //     window?.open(`https://www.google.com/maps/search/?api=1&query=${result}`, '_blank')?.focus();
            // };
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                    // onClick={() => openGmaps(params?.data?.location != "" ? params?.data?.location : full_address)}
                >
                    <>
                        {!params?.data?.city && !address && params?.data?.location == '' ? <div></div> : (
                            <>
                                <Typography variant="subtitle1">
                                    {' '}
                                    <MapPin size={14} strokeWidth={1} color="#000000"/> {params?.data?.city}
                                </Typography> <Typography variant="caption">{address}</Typography>
                            </>
                        )}
                    </>
                </div>
            )
        },
    },
    {
        id: 'externalId',
        numeric: false,
        label: 'מזהה חיצוני',
        hide: true,
        type:'numOrStr',
        align: 'center',
        extendedSearchProperties: ['externalId'],
        searchQueryPlaceholder: 'מזהה חיצוני'
    }, {
        id: 'ref_id',
        numeric: false,
        label: 'מזהה שכפול',
        type:'numOrStr',
        hide: true,
        align: 'center',
    },
    {
        id: 'mName',
        type: 'string',
        label: 'משטח',
        numeric: false,
        hide: true,
        align: 'center',
        type:'numOrStr',
        priority: -1,
        pinnedRowProps: {
            action: "uniqueCount"
        },
        enableRowGroup: true,
        editable: (row) => {
            return true
        },
        cellRenderer({params}) {
            if (params?.node?.group) return params?.value || '';
            if (params?.node?.rowPinned === "top") {
                return (
                    <span style={{
                        display: 'flex',
                        alignItems: "center",
                    }}>
                        {params?.value || ''} {params?.value ? <Package style={{
                        marginRight: '5px',
                        color: "#dc6c00"
                    }}/> : ""}
                    </span>
                )
            }
            return params?.value || '';
        },
    },
    {
        id: "debt",
        label: "חוב לקוח",
        numeric: false,
        hide: true,
        priority: -1,
        extractFunc: (row) => {
            if (row.id) {
                const client = JSDB().getClient(Number(row.id)).first;
                const wall = client?.debtWall
                return wall ? roundDecimals(wall?.moneyOut - wall?.moneyIn) : undefined;

            } else
                return undefined

        },
    },
    {
        id: "obligo",
        label: "אובליגו",
        numeric: false,
        hide: true,
        priority: -1,
        extractFunc: (row) => {
            if (row.id) {
                const client = JSDB().getClient(Number(row.id)).first;
                return client?.obligo

            } else
                return undefined

        },
    },
    {
        id: "payment_notes",
        label: "תנאי תשלום",
        numeric: false,
        hide: true,
        priority: -1,
        extractFunc: (row) => {
            if (row.id) {
                const client = JSDB().getClient(Number(row.id)).first;
                return client?.payment_notes

            } else
                return undefined

        },
    },


];

interface orderFilters {
    agents: Filter[];
    collectors: Filter[];
    drivers: Filter[];
    clients: Filter[];
    cities: Filter[];
    categories: Filter[];
    orders: Filter[];
    order_assistent: Filter[];
    dvs: Filter[];
    products: Filter[];
    productsCategories: Filter[];
    days: Filter[];
}

const OrdersDataSheet2 = ({
                              setFiltersToMasterFilter,
                              products,
                              agentsNames,
                              collectorsNames,
                              driversNames,
                              date,
                              activeState,

                              tempData, setTempData,
                              updatedRowIds,
                              updatedDriverRowIds,
                              callPrevOrder,
                              callPrevDaily,
                              statuses,

                              setSingleDelete,
                              deleteOrder,
                              saveOrder,
                              openDrawer,
                              handleOpenDrawer,
                              setDrawerSingleData,
                              activeRow,
                              setActiveRow,

                              setAll,

                              restoreAllChanges,
                              dvs_flag,
                              setDvsFlag,
                              showProducts,
                              setShowProducts,
                              orderFilters,
                              onApplyFilter,
                              showData,
                              sortBy,
                              setOpenDrawer,
                              handleApplySort,
                              addCustomerFilter,
                              removeAllFilters,
                              setOpenStatistics,
                              openStatistics,
                              setActiveP, cancelDV,
                              refreshRowIds,
                              distinctStatus,
                              reorderDrivers,
                              setReorderDrivers,
                              clearEditFromAbove,
                              fullInventory,
                              pinnedInventoryEditable,
                              setPinnedInventoryEditable,
                              pinnedOldInventoryEditable,
                              setPinnedOldInventoryEditable,
                              callClientVisit,
                              clientVisit,
                              setRefreshRowIds,
                              onRemoveFilter,
                              newClientOrder,
                              externalIdSearch,
                              refreshClientData,
                              tableRef,
                              setHistoryState,
                                historyState,
                          }: {
    setFiltersToMasterFilter: (activeFiltersKey: string, filterId: string, label?: string) => void,
    products: Product[];
    agentsNames: string[];
    collectorsNames: string[];
    driversNames: string[];
    date: Date,
    activeState: OrderActiveState,
    tempData: OrderData[],
    setTempData: any,
    callPrevOrder: any,
    updatedRowIds: any,
    updatedDriverRowIds: any,
    callPrevDaily: any,
    statuses: any[]

    setSingleDelete: any,
    deleteOrder: any,
    saveOrder: any,
    openDrawer: any,
    setOpenDrawer: any,
    handleOpenDrawer: any,
    setDrawerSingleData: any,
    activeRow: any,
    setActiveRow: any,

    setAll: any

    restoreAllChanges: any
    dvs_flag: boolean
    setDvsFlag: any
    showProducts: any,
    setShowProducts: any
    orderFilters: orderFilters
    onApplyFilter: any
    showData: any,
    sortBy: any,

    handleApplySort: any
    addCustomerFilter: any
    removeAllFilters: any,
    setOpenStatistics: any,
    openStatistics: any,
    cancelDV: any,
    refreshRowIds: any,
    setActiveP,
    distinctStatus,
    reorderDrivers,
    setReorderDrivers,
    clearEditFromAbove: any,
    fullInventory: any,
    pinnedInventoryEditable: any,
    setPinnedInventoryEditable: any,
    pinnedOldInventoryEditable: any,
    setPinnedOldInventoryEditable: any,
    callClientVisit: any,
    clientVisit,
    setRefreshRowIds: any,
    newClientOrder: any,
    refreshClientData: any,
    externalIdSearch: boolean
    tableRef: MutableRefObject<any>
    onRemoveFilter: any,
    setHistoryState:any,
    historyState:any,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [selectedCustomersSend, setSelectedCustomersSend] = React.useState<any>(undefined)
    const [reorderDialog, setReorderDialog] = React.useState<any>(undefined)
    const [distributeDialogOn, setDistributeDialogOn] = React.useState<any>(undefined)
    const [disableKeyBoard, setDisableKeyBoard] = React.useState(false);
    const [color_surface, setColorSurface] = React.useState(true);
    const [show_collectors, setShowCollector] = React.useState(parseInt(localStorage.getItem('show_collectors') ?? '0') == 1);
    const [openCustomerDrawer, setOpenCustomerDrawer] = React.useState(false);
    // console.log('customers/order-list' + `-inventory_${dateObjectToString(date)}`)
    // const [pinnedInventoryEditable, setPinnedInventoryEditable] = React.useState(JSON.parse(localStorage.getItem('customers/order-list' + `-inventory_${dateObjectToString(date)}`) ?? `{"customer":"מלאי ידני"}`));
    // const [pinnedOldInventoryEditable, setPinnedOldInventoryEditable] = React.useState(JSON.parse(localStorage.getItem('customers/order-list' + `-inventory_old_${dateObjectToString(date)}`) ?? `{"customer":"מלאי ישן"}`));

    const [selectedCustomerId, setSelectedCustomerId] = React.useState(undefined);
    const [displayProductsWithValues, setDisplayProductsWithValues] = React.useState(true);
    React.useEffect(() => {
        localStorage.setItem(tableControls.tableName + `-inventory_${dateObjectToString(date)}`, JSON.stringify(pinnedInventoryEditable));

    }, [pinnedInventoryEditable])
    React.useEffect(() => {
        localStorage.setItem(tableControls.tableName + `-inventory_old_${dateObjectToString(date)}`, JSON.stringify(pinnedOldInventoryEditable));

    }, [pinnedOldInventoryEditable])

    const [debtLoad, setDebtLoad] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (debtLoad) {
            buildClientCartesetDebt(new Date()).then(r =>

                setRefreshRowIds(new Set(tempData.map((t) => orderKey(t)))))
        } else {

        }
    }, [debtLoad]);
    const [cols, setCols] = React.useState<HeadCell[]>(headCells);
    useEffect(() => {

        localStorage.setItem('show_collectors', show_collectors ? '1' : '0');

    }, [show_collectors]);

    React.useEffect(() => {
        const newCols = cloneDeep((headCells))
        const notes2 =  JSDB().getUser().first?.getJsonDesc("notes2")
        if (notes2) {
            newCols.push(    {
                id: "notes2",
                label: notes2.name,
                numeric: false,
                hide: true,
                priority: -1,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                editable: (row) => {
                    return true
                },
            },)
        } else {
            newCols.push(    {
                id: "notes2",
                label: "הערות 2",
                numeric: false,
                hide: true,
                priority: -1,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                editable: (row) => {
                    return true
                },
            })
        }
        const notes3 =  JSDB().getUser().first?.getJsonDesc("notes3")
        if (notes3) {
            newCols.push(    {
                id: "notes3",
                label: notes3.name,
                numeric: false,
                hide: true,
                priority: -1,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                editable: (row) => {
                    return true
                },
            },)
        } else {
            newCols.push(    {
                id: "notes3",
                label: "הערות 3",
                numeric: false,
                hide: true,
                priority: -1,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                editable: (row) => {
                    return true
                },
            })
        }
        const notes4 =  JSDB().getUser().first?.getJsonDesc("notes4")
        if (notes4) {
            newCols.push(    {
                id: "notes4",
                label: notes4.name,
                numeric: false,
                hide: true,
                priority: -1,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                editable: (row) => {
                    return true
                },
            },)
        } else {
            newCols.push(    {
                id: "notes4",
                label: "הערות 4",
                numeric: false,
                hide: true,
                priority: -1,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                editable: (row) => {
                    return true
                },
            })
        }

        const notes5 = JSDB().getUser().first?.getJsonDesc("notes5")
        if (notes5) {
            newCols.push(    {
                id: "notes5",
                label: notes5.name,
                numeric: false,
                hide: true,
                priority: -1,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                editable: (row) => {
                    return true
                },
            },)
            newCols.push(    {
                id: "notes5Dv",
                label: notes5.name,
                numeric: false,
                hide: true,
                priority: -1,
                extractFunc: (row) => {
                    if (row.dvs?.[0]?.notes5) {
                        return row.dvs?.[0]?.notes5

                    } else
                        return undefined

                },

            },)
        } else {
            newCols.push(    {
                id: "notes5",
                label: "הערות 5",
                numeric: false,
                hide: true,
                priority: -1,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                editable: (row) => {
                    return true
                },
            })
            newCols.push(    {
                id: "notes5Dv",
                label: "הערות 5 תעודה",
                numeric: false,
                hide: true,
                priority: -1,

                extractFunc: (row) => {
                    if (row.dvs?.[0]?.notes5) {
                        return row.dvs?.[0]?.notes5

                    } else
                        return undefined

                },

            })

        }
        const arr = [...(showProducts ? products.slice().filter((p) => {
                const categoryFilter = orderFilters.productsCategories.length == 0 || orderFilters.productsCategories[0].values.some((v) => v == p.category)
                const productFilter = orderFilters.products.length == 0 || orderFilters.products[0].values.some((v) => v == p.name)
                return  categoryFilter && productFilter
            }
        ).map((p) => ({
            id: `p_${p.id}`,
            type: 'number',
            numeric: true,
            editable: (row) => {
                return !row?.node?.data?.readonly
            },
            aggFunc: 'sum',
            pinnedRowProps: {
                action: "sum",
            },
            label: (p.orderName && p.orderName.length > 0) ? p.orderName : p.name,
            valueLimit: {
                min: 0,
            },
            cellRenderer({params}) {
                const isBox = params?.node?.data?.[`u_${p.id}`] === 1;
                return (
                    <>
                        {isBox ? <Package style={{
                            marginRight: '5px',
                            color: "black",
                            width: "14px",
                            height: "14px",

                        }}/> : ""}
                        {params?.value || ''}
                    </>
                )
            }
        })) : [])]

        if(show_collectors) {
            const cc = JSDB().getCollectors().first
            if (cc.size > 0) {
                cc.toArray().forEach((c) => {
                    newCols.push({
                        id: `c_${c.id}`,
                        type: 'number',
                        numeric: true,
                        aggFunc: 'sum',
                        pinnedRowProps: {
                            action: "sum",
                        },
                        editable:true,
                        label: c.user_name,

                    })

                })

                if(JSDB().getCompany()=='misrael') {

                    const category2 = JSDB().getKartonCategories()
                    if(category2.size>0)
                        category2.toArray().forEach((p) => {
                        newCols.push({
                            id: `c_${p}`,
                            type: 'number',
                            numeric: true,
                            editable: (row) => {
                                return false
                            },
                            aggFunc: 'sum',
                            pinnedRowProps: {
                                action: "sum",
                            },
                            label: p,

                        })
                    })
                }
            }
        }

        newCols.push(
            ...arr
        );

        setCols(newCols)
    }, [showProducts,show_collectors,orderFilters.productsCategories,orderFilters.products])

    const [documentAction, setDocumentAction] = React.useState(undefined);
    const openOrders = useOpenOrders2();
    //
    // React.useEffect(() => {
    //     handleApplySort(sortBy.orderBy, sortBy.order, sortBy.property2);
    // }, [orderFilters]);

    const [menuItems, setMenuItems] = React.useState<{
        agent: {
            label: string,
            value: string
        }[],
        driverName: {
            label: string,
            value: string
        }[],
        orderDriver: {
            label: string,
            value: string
        }[],
        status: {
            label: string,
            value: string
        }[],
        collectorName: {
            label: string,
            value: string
        }[],
    }>({
        agent: [],
        driverName: [],
        orderDriver: [],
        status: [],
        collectorName: []
    })

    React.useEffect(() => {
        const newMenuItems = {
            agent: [],
            driverName: [],
            status: [],
            collectorName: [],
            orderDriver: []
        }
        newMenuItems.agent = (agentsNames as any).map((a) => ({
            label: a,
            value: a
        }))
        newMenuItems.driverName = (driversNames as any).map((a) => ({
            label: a,
            value: a
        }))
        newMenuItems.orderDriver = (driversNames as any).map((a) => ({
            label: a,
            value: a
        }))
        newMenuItems.status = (distinctStatus as any).map((a) => ({
            label: a,
            value: a
        }))
        newMenuItems.collectorName = (collectorsNames as any).map((a) => ({
            label: a,
            value: a
        }))
        setMenuItems(newMenuItems)
    }, [agentsNames, driversNames, distinctStatus, collectorsNames])

    const [openCustomeRangePicker, setOpenCustomeRangePicker] = React.useState(false);
    const [withOrdersFilter, setWithOrdersFilter] = React.useState(orderFilters?.orders?.length);
    useEffect(() => {
        setWithOrdersFilter(orderFilters?.orders?.length);
    }, [orderFilters])

    const [productChipMoved, setProductChipMoved] = React.useState(false);

    const onColumnMoveCallback = React.useCallback((params) => {
        params?.columns?.some((col) => {
            if (col?.colDef?.id?.startsWith("p_")) {
                setProductChipMoved(true);
            }
        });
    }, []);

    const chipDetails = useMemo(() => {
        const chips = [
            {
                label: (params) => customGroupLabelForChip(params, ["driverName"], "קבץ לפי נהגים", "הסר קיבוץ לפי נהגים"),
                action: (params) => {
                    const columnsState = params?.columnApi?.getColumnState();
                    const isGrouped = columnsState.find(c => c.colId.split("_")[0] === "driverName")?.rowGroup;
                    if (isGrouped) {
                        removeDragFromTable(params);
                    } else {
                        addDragToTable(params);
                    }
                    customGroup(params, ["driverName"]);
                },
                tooltip: "קבץ לפי נהגים",
                icon: <LocalShippingIcon/>,
            },
            {
                action: (params) => {
                    if (showProducts) {
                        setCols(headCells)
                        setShowProducts(false)
                    } else {
                        setShowProducts(true)
                    }

                },
                label: () => showProducts ? "הסתר מוצרים" : "הצג מוצרים",
                icon: <IconInfoCircle/>,
                tooltip: "הצג מוצרים בטבלה",
                active: () => showProducts,
            },
            {
                action: (params) => {
                    const allColsObj = {};
                    params?.columnApi?.getColumns().filter(col => col?.colDef?.id?.includes("p_"))?.forEach((col) => {
                        allColsObj[col.colDef.id] = col
                    });
                    let columnsWithValue = {};
                    params?.api?.forEachNodeAfterFilterAndSort((node) => {
                        if (node?.data) {
                            Object.keys(node.data).forEach((col) => {
                                if (col.startsWith("p_")) {
                                    if (node.data?.[col] && !columnsWithValue?.[col]) {
                                        columnsWithValue = {
                                            ...columnsWithValue,
                                            [col]: true,
                                        };
                                    }
                                }
                            });
                        }
                        if (node?.childrenAfterGroup) {
                            node?.childrenAfterGroup?.forEach((child) => {
                                Object.keys(child.data).forEach((col) => {
                                    if (col.startsWith("p_")) {
                                        if (child.data?.[col] && !columnsWithValue?.[col]) {
                                            columnsWithValue = {
                                                ...columnsWithValue,
                                                [col]: true,
                                            };
                                        }
                                    }
                                });
                            })
                        }
                    });
                    if (displayProductsWithValues) {
                        setDisplayProductsWithValues(false);
                        Object.keys(allColsObj).forEach((col) => {
                            if (!columnsWithValue?.[col]) {
                                params?.columnApi?.setColumnVisible(col, false);
                            }
                        });
                    } else {
                        setDisplayProductsWithValues(true);
                        Object.keys(allColsObj).forEach((col) => {
                            params?.columnApi?.setColumnVisible(col, true);
                        });
                    }


                },
                label: () => displayProductsWithValues ? "סנן מוצרים ללא ערך" : "הצג את כל המוצרים",
                icon: <IconInfoCircle/>,
                tooltip: "סנן מוצרים ללא ערך"
            },
            // {
            //     action: () => {
            //         setColorSurface((p) => !p);
            //         // re render the table
            //         // setCols((p) => [...p]);
            //     },
            //     label: () => color_surface ? "ללא צביעת משטחים" : "צבע משטחים",
            //     icon: <IconInfoCircle />,
            //     tooltip: "צבע משטחים"
            // },
            {
                action: () => {

                    setOpenStatistics((p) => {
                        return p ? undefined : {
                            open: true,
                            fromDate: undefined,
                            toDate: undefined,
                            name: 'orders-customer-statistics'
                        }

                    })
                },
                label: () => openStatistics ?
                    <span id="orders-customer-statistics">הסתר סטטיסטיקה</span> : "הצג סטטיסטיקה",
                icon: <IconInfoCircle/>,
                tooltip: "הצג סטטיסטיקה",
                active: () => openStatistics,
            },
            {
                action: () => {
                    if (activeState.day == -1) {
                        if (updatedRowIds.size > 0 && dvs_flag) {
                            Swal.fire({
                                title: 'ישנם שינויים ששית במצב תעודות',
                                text: 'מעבר למצב רגיל מוחק את השינויים האם אתה בטוח?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: theme.palette.primary.main,
                                cancelButtonColor: theme.palette.error.main,
                                confirmButtonText: 'עבור',
                                cancelButtonText: 'בטל'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    /* Perform insert, update, and delete to the database here */
                                    restoreAllChanges();
                                    setDvsFlag((p) => !p);
                                }
                            });
                        } else {
                            restoreAllChanges();
                            setDvsFlag((p) => !p);
                        }
                    }
                },
                label: () => "הנפקת תעודת משלוח גורפת",
                icon: <IconInfoCircle/>,
                tooltip: "הנפקת תעודת משלוח גורפת"
            },

            {
                action: () => {
                    if (clientVisit) {
                        callClientVisit(null)
                    } else
                        setOpenCustomeRangePicker((p) => true)
                },
                label: () => <span id="orders-customer-range">פילטר לקוחות מזמינים</span>,
                icon: <CalendarMonth/>,
                tooltip: "סנן לפי תאריכים",
                active: () => clientVisit,
            },
            {
                action: (params) => {
                    if (show_collectors) {
                        setShowCollector(false)
                    } else {
                        setShowCollector(true)
                    }

                },
                label: () => show_collectors ? "הסתר מלקטים" : "הצג מלקטים",
                icon: <IconInfoCircle/>,
                tooltip: "הצג מלקטים בטבלה",
                active: () => show_collectors,
            },
            {
                action: (params) => {
                    setDebtLoad(true)
                },
                label: (params) => "משוך חוב לקוח",
                icon: <IconInfoCircle/>,
                tooltip: "עדכן חוב לקוח",
                active: (params) => true
            },

            {
                action: () => {
                    if (withOrdersFilter) {
                        onRemoveFilter({
                            property: 'withOrders',
                            contains: '',
                            custom: (d) => Boolean(d?.orderId) || Boolean(d?.hasBeenEdited)
                        });
                    } else {
                        onApplyFilter({
                            property: 'withOrders',
                            contains: '',
                            custom: (d) => Boolean(d?.orderId) || Boolean(d?.hasBeenEdited)
                        });
                    }
                    setFiltersToMasterFilter("orders", "withOrders", 'לקוחות עם הזמנות');
                },
                label: () => <span id="orders-customer-range">
                    {
                        withOrdersFilter ? "הצג את כל הלקוחות" : "הצג לקוחות עם הזמנות"
                    }
                </span>,
                icon: <IconInfoCircle/>,
                tooltip: "הצג לקוחות עם הזמנות",
                active: () => withOrdersFilter,
            }
        ];

        if (productChipMoved) {
            const saveProductsOrder = {
                action: (params) => {
                    const productIds: number[] = [];
                    params?.columnApi?.getColumnState()?.forEach(col => {
                        if (col?.colId?.startsWith("p_")) {
                            const id = col?.colId?.split("_")[1];
                            productIds.push(Number(id));
                        }
                    });
                    dispatch(updateMassCustomerProduct({
                        ids: productIds,
                        position: 1
                    }));
                    setProductChipMoved(false);
                },
                label: () => "שמור סדר מוצרים",
                icon: <DeviceFloppy/>,
                tooltip: "שמור סדר מוצרים",
                active: () => false,
            };
            chips.unshift(saveProductsOrder);
        }

        return chips;
    }, [activeState, updatedRowIds, dvs_flag, showProducts, color_surface, openStatistics, displayProductsWithValues, clientVisit, withOrdersFilter, productChipMoved,show_collectors]);

    const saveDataCallback = React.useCallback(async (data) => {
        try {
            return await saveOrder(data.map((d) => d.id))
        } catch (e) {
            console.log(e)
            return false
        }

    }, [saveOrder])

    const addDataAction = useMemo(() => {
        return {
            tooltip: "הוסף הזמנה חדשה",
            label: "חפש הזמנה או לקוח",
            component(tableApi) {
                return (
                    <Autocomplete
                        id='pick-customer'
                        options={tempData}
                        getOptionLabel={(option: any) => `מזהה ${externalIdSearch ? option.externalId : option.id} ${option?.customer}  ${option?.orderId}`}
                        autoHighlight
                        onChange={(event, newValue) => {
                            if (newValue) {
                                const node = tableApi?.getRowNode(orderKey(newValue));
                                if (node) {
                                    tableApi?.ensureNodeVisible(node, 'top');
                                    // const pCol = tableApi?.getColumnDefs().find((c) => c.field?.startsWith("p_"));
                                    // setTimeout(() => {
                                    //     tableApi?.startEditingCell({
                                    //         rowIndex: node?.rowIndex,
                                    //         colKey: pCol?.colId,
                                    //     });
                                    // }, 100);


                                } else {
                                    setAll(false)
                                    setOpenDrawer(!openDrawer)
                                    setDrawerSingleData([newValue])

                                }
                            }
                        }}
                        sx={{width: 150, marginTop: "-5px", marginRight: "5px"}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='הוסף הזמנה'
                                variant='outlined'
                                size='small'
                            />
                        )}
                    />
                )
            },
        }
    }, [tempData, showProducts, openDrawer]);


    let timeOutId: NodeJS.Timeout | null = null;
    const handleOnActiveCellChange = React.useCallback((params) => {
        if (!openStatistics) return;

        const node = params?.api?.getDisplayedRowAtIndex(params?.rowIndex);
        const pId = params?.column?.colDef?.id.startsWith("p_") ? params?.column?.colDef?.id.split("_")[1] : undefined;
        if (node?.data) {
            if (timeOutId)
                clearTimeout(timeOutId);
            timeOutId = setTimeout(() => {
                setActiveRow(node?.data);
                if(pId)
                setActiveP(Number(pId));
                else
                setActiveP(null);
            }, 200);
        } else {
            setActiveRow(undefined);
            setActiveP(null);
        }
    }, [openStatistics]);

    const editCallbackMany = useCallback(async (params) => {
        const grp = groupByUnStr(params, (p) => orderKey(p?.data))
        setTempData((prev) =>
            prev.map((d, i) => {
                    const arr = grp[orderKey(d)]
                    if (arr) {
                        const param0 = arr[0]
                        const newD = {...param0?.node?.data}
                        newD.me = newD
                        let been = false
                        arr.forEach((p) => {
                            if (p?.newValue != p?.oldValue) {
                                been = true
                                updatedRowIds.add(orderKey(p?.node?.data));
                                updatedDriverRowIds.add(p?.node?.data?.id);
                                newD[`${p?.column?.colDef?.field}`] = p?.newValue
                                if (JSDB().userCofcConf?.conversion_rate_blocker == 1 || JSDB().userCofcConf?.conversion_rate_blocker == 2) {
                                    if (p?.column?.colDef?.field?.includes('p_')) {
                                        const id = p?.column?.colDef?.field?.split('_')[1]
                                        if (JSDB().getClientProduct(Number(id)).first?.getOrderProduct()?.extraUnitOn() == true && newD[`u_${id}`] == undefined) {
                                            newD[`u_${id}`] = 1
                                        }
                                    }
                                }
                            }
                        })

                        if (been)
                            return newD
                    } else {
                        return d
                    }
                    return d
                }
            )
        );
    }, [updatedRowIds, updatedDriverRowIds])
    const [previewOpen, setPreviewOpen] = React.useState(undefined);
    const tableDividers = JSON.parse(localStorage.getItem('orders-table-dividers') || '[]');
    const tableControls: ITableControls = {
        tableName: 'customers/order-list',
        sidebar: true,
        localisation: true,
        fixH: true,
        manyEditTime:1000,
        chipDisplayAmount: 7,
        disableDefaultEditActions: true,
        groupBy: {
            enable: true,
            rowGroup: true,
            pivot: true,
            value: true,
            allowSave: true,
        },
        drag: {
            enable: true,
            complex: true,
            multiRow: true,
            customeDragEndAction(params) {
                const overNode = params?.overNode;
                const overNodeDriverPosition = overNode?.data?.driver_position;
                const newStartPosition = overNodeDriverPosition - params?.nodes?.length > 0 ? overNodeDriverPosition - params?.nodes?.length : 0;
                params?.nodes?.forEach((n, i) => {
                    const newDriverPosition = newStartPosition + i + 1;
                    updatedRowIds.add(orderKey(n?.data));
                    updatedDriverRowIds.add(n?.data?.id);
                    setTempData((prev) =>
                        prev.map((d, i) => {
                                if (d.id == n?.data?.id) {
                                    const newD = {...n?.data}
                                    newD.driver_position = newDriverPosition;
                                    newD.me = newD
                                    return newD
                                } else {
                                    return d
                                }
                            }
                        )
                    );
                });
                setReorderDrivers(true);
            },
        },
        selection: {
            enable: true,
            mode: "multiple"
        },
        extractId: (row) => orderKey(row?.data),
        cellRenderer: (params, column, i) => {

            if (column.cellRenderer) {
                return column.cellRenderer({
                    params,
                    column,
                    i,
                    setOpenCustomerDrawer,
                    setSelectedCustomerId,
                    updatedRowIds,
                    setTempData,
                    setPreviewOpen
                })
            }

            if (typeof params?.value === "object") {
                const value = params?.value?.value || "";
                return value;
            }
            return params?.value || '';
        },
        excelPrependTop: (col) => {
            if (col?.includes('p_')) {
                const id = col?.split('_')[1]
                return JSDB().getClientProduct(Number(id)).first?.getOrderProduct()?.englishName || ''
            }
        },
        rowStyle: (params) => {
            let style: any = {'borderLeft': `1px solid ${theme.palette.divider}`};
            if (params?.node?.rowPinned) return {};

            if (params?.node?.data?.status == "סופק ללקוח")
                style = {...style, backgroundColor: '#638fff'}
            else if (params?.node?.data?.readonly && params?.node?.data?.all_dvs_printed)
                style = {...style, backgroundColor: '#9ffd9f'}
            else if (params?.node?.data?.readonly)
                style = {...style, backgroundColor: '#adffe1'}
            else if (params?.node?.data?.status == "בוצעה")
                style = {...style, backgroundColor: '#cda7e8'}
            else if (params?.node?.data?.status == "לוקט")
                style = {...style, backgroundColor: '#ffa563'}

            else if (color_surface && params?.columnApi?.getColumn('mName')?.visible && params?.node?.data?.mName % 2 !== 0) {
                style = {
                    ...style,
                    'background-color': "#808080a6",
                }
            }

            if (params.node?.rowIndex > 0 && params?.columnApi?.getColumn('driverName')?.sort) {
                const previousNode = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);
                if (previousNode && previousNode?.data?.driverName !== params?.node?.data?.driverName) {
                    style = {...style, 'border-top': 'solid 5px black'}
                }

            }
            if (tableDividers?.includes(params?.column?.colDef?.field)) {
                style = {
                    ...style,
                    'border-right': `1.5px solid black`,
                }
            }


            return style
        },
        floatingFilters: false,
        replaceContextMenu: true,
        disableEditableCellStyle: true,
        sideBarActions: [],
    }

    const pinnedControl = useMemo(() => {
        const onlyfirst = {}
        const onlysecond = {}
        const fInventory = {}
        const totalInventory = {}
        const afterInv = {}
        if (showProducts) {
            products.forEach((p) => {
                fInventory[`p_${p?.id}`] = (d) => 0

                totalInventory[`p_${p?.id}`] = (d) => (0)
                const conversion_rate = JSDB().getClientProduct(Number(p.id)).first?.getOrderProduct()?.conversion_rate ?? 0
                onlysecond[`p_${p?.id}`] = (d) => {
                    const v = (d?.[`p_${p?.id}`] ?? 0)
                    const u = (d?.[`u_${p?.id}`] ?? 0)
                    if (v && u) {
                        return v
                    } else if (v && conversion_rate) {
                        return v / conversion_rate
                    }

                    return 0
                }
            })

            products.forEach((p) => {
                const extra = JSDB().getClientProduct(Number(p.id)).first?.getOrderProduct()?.extraUnitOn()
                const conversion_rate = JSDB().getClientProduct(Number(p.id)).first?.getOrderProduct()?.conversion_rate ?? 0
                onlyfirst[`p_${p?.id}`] = (d) => {
                    const v = (d?.[`p_${p?.id}`] ?? 0)
                    const u = (d?.[`u_${p?.id}`] ?? 0)
                    if (v && !u) {
                        return v
                    } else if (v && u && conversion_rate) {
                        return v * conversion_rate
                    }
                    return 0
                }
            })

            fullInventory.forEach((p) => {
                fInventory[`p_${p?.id}`] = (d) => (p.totalInventory ?? 0)
                const x = Number(pinnedInventoryEditable[`p_${p?.id}`])

                totalInventory[`p_${p?.id}`] = (d) => ((p.totalInventory ?? 0) + (x && !isNaN(x) ? x : 0))
            })
            fInventory.customer = (d) => 'מלאי מחסן'
            totalInventory.customer = (d) => 'מלאי מחסן + חדש'
            afterInv.customer = (d) => 'מלאי ישן + חדש'
            products.forEach((p) => {
                const x = Number(pinnedInventoryEditable[`p_${p?.id}`])
                const y = Number(pinnedOldInventoryEditable[`p_${p?.id}`])

                afterInv[`p_${p?.id}`] = (d) => (y && !isNaN(y) ? y : 0) + (x && !isNaN(x) ? x : 0)
            })
        }

        return {
            pinnedRowControlsNew: [{
                id: 1,
                label: "סיכום הזמנות",
                func: {
                    customer: (d) => 'סיכום הזמנות',
                }
            },
                {
                    id: 5,
                    label: "סיכום כמות מרכזית",
                    func: {
                        customer: (d) => 'סיכום כמות מרכזית',
                    },
                    valueGetter: onlyfirst
                },
                {
                    id: 6,
                    label: "סיכום כמות משנית",
                    func: {
                        customer: (d) => 'סיכום כמות משנית',
                    },
                    valueGetter: onlysecond
                },
                {
                    id: 11,
                    label: "מלאי ישן",

                    editableData: pinnedOldInventoryEditable,
                    editable: (params) => {
                        setPinnedOldInventoryEditable(prevState => {
                            const x = {
                                ...prevState,


                            }
                            x.hasBeenEdited = true;
                            x[`${params?.colDef?.field}`] = params?.value
                            x[`edit_${params?.colDef?.field}`] = true
                            return x
                        })

                        return false
                    }
                },
                {
                    id: 12,
                    label: "מלאי ישן+חדש",
                    func: afterInv
                },
                {
                    id: 2,
                    label: "מלאי חדש",

                    editableData: pinnedInventoryEditable,
                    editable: (params) => {

                        setPinnedInventoryEditable(prevState => {
                            const x = {
                                ...prevState,


                            }
                            x.hasBeenEdited = true;
                            x[`${params?.colDef?.field}`] = params?.value
                            x[`edit_${params?.colDef?.field}`] = true
                            return x
                        })

                        return false
                    }
                }, {
                    id: 3,
                    label: "לאחר חלוקה",

                    sumByConfigurations: {
                        func: 'minus',
                        name: 'customer',
                        ids: [12, 1]
                    }

                },

                {
                    id: 7,
                    label: "מלאי מחסן",
                    func: fInventory
                },
                {
                    id: 8,
                    label: "מלאי מחסן + חדש",
                    func: totalInventory
                },
                {
                    id: 9,
                    label: "חיסור מלאי סופי",

                    sumByConfigurations: {
                        func: 'minus',
                        name: 'customer',
                        ids: [8, 1]
                    }
                },
            ],
            pinnedRowTopDefault: [1],
            pinnedRowBotDefault: [],
            control: true,


        }
    }, [pinnedInventoryEditable, pinnedOldInventoryEditable, products, showProducts, fullInventory])
    const [openSelectProductsToExport, setOpenSelectProductsToExport] = React.useState(undefined);
    const [externalAnchor, setExternalAnchor] = React.useState(undefined);
    const [selectedForDuplicate, setSelectedForDuplicate] = React.useState([]);

    const extentedContextMenu = React.useCallback((params) => {
        if (params)
            return contextMenu2(params, setTempData, removeAllFilters, onApplyFilter, addCustomerFilter, activeState, dvs_flag, updatedRowIds, saveOrder, cancelDV, date, theme, deleteOrder, updatedDriverRowIds, setSingleDelete, setSelectedCustomersSend, setDistributeDialogOn, setReorderDialog, newClientOrder, setDocumentAction, setOpenSelectProductsToExport, setSelectedForDuplicate, setExternalAnchor) as any;
        return []
    }, [dvs_flag, updatedRowIds, updatedDriverRowIds, activeState, date, theme, saveOrder, cancelDV, deleteOrder, removeAllFilters, onApplyFilter, addCustomerFilter, setTempData, setSingleDelete, newClientOrder]);
    return (
        <>
            <DataSheetGridContext.Provider
                value={{
                    activeRow,
                    openDrawer,
                    setOpenDrawer,
                    handleOpenDrawer,
                    setSingleDelete,
                    setDrawerSingleData,
                    callPrevOrder,
                    cancelDV,
                    updatedRowIds,
                    updatedDriverRowIds,
                    date,
                    disableKeyBoard: setDisableKeyBoard,
                    saveOrder,
                    callPrevDaily,
                    deleteOrder,
                    dvs_flag,
                    activeState,
                    onApplyFilter,
                    addCustomerFilter,
                    removeAllFilters,
                    setAll,
                    setActiveRow,
                }} disableKeyBoard
            >
                <AgTable
                    columns={cols}
                    data={showData}
                    outSideTableRef={tableRef}
                    tableControls={tableControls}
                    refreshCells={refreshRowIds}
                    menuiItems={menuItems}
                    addDataAction={addDataAction}
                    extentedContextMenu={extentedContextMenu}
                    clearEditFromAbove={clearEditFromAbove}
                    chipDetails={chipDetails}
                    saveDataCallback={saveDataCallback}
                    customeCancelEdit={() => restoreAllChanges()}
                    editCallbackMany={editCallbackMany}
                    pinnedControl={pinnedControl}
                    onCellFocused={handleOnActiveCellChange}
                    onColumnMoveCallback={onColumnMoveCallback}
                    filterQ={true}
                />
            </DataSheetGridContext.Provider>
            {openCustomerDrawer ? <AddCustomer
                open={openCustomerDrawer}
                handleDrawerOpen={() => setOpenCustomerDrawer(false)}
                id={selectedCustomerId?.id}
                editable={true}
                updateCallBack={() => {

                    refreshClientData()
                    setSelectedCustomerId(undefined)
                }}
            /> : undefined}


            {openStatistics?.open ?
                <ChipRangeDate open={openStatistics} setOpen={(o) => setOpenStatistics(
                    (p) => ({...p, open: o})
                )} filterFunc={(range) => {
                    setOpenStatistics((p) => {
                        return {
                            open: false,
                            fromDate: range[0],
                            toDate: range[1],
                            name: 'orders-customer-statistics'
                        }

                    })
                }
                }/> : undefined
            }
            {openCustomeRangePicker ?
                <ChipRangeDate open={openCustomeRangePicker} setOpen={setOpenCustomeRangePicker}
                               filterFunc={callClientVisit}/> : undefined
            }
            {distributeDialogOn ?
                <FormDialog
                    distributeDialogOn={distributeDialogOn}
                    setData={setTempData}
                    updatedRowIds={updatedRowIds}
                    setDistributeDialogOn={setDistributeDialogOn}
                    setRefreshRowIds={setRefreshRowIds}
                /> : undefined
            }
            {
                reorderDialog ? (
                    <ReorderDialogOrders reorderDialog={reorderDialog} setReorderDialog={setReorderDialog}
                                         setData={setTempData}
                                         updatedRowIds={updatedRowIds}
                                         updatedDriverRowIds={updatedDriverRowIds}
                                         setRefreshRowIds={setRefreshRowIds}/>

                ) : null
            }
            {selectedCustomersSend != undefined ? <SendMessageDialog
                open={selectedCustomersSend != undefined}
                setOpen={(open) => setSelectedCustomersSend(undefined)}

                toSelected={selectedCustomersSend?.selected ?? []}
                files={selectedCustomersSend?.files ?? []}
                msg={selectedCustomersSend?.msg ?? ""}
                subject={selectedCustomersSend?.subject ?? ""}
                defaultPlatform={selectedCustomersSend?.defaultPlatform ?? "email"}
                withFiles={false}
                action_date={date}
                onSend={selectedCustomersSend?.onSend}
            /> : undefined}
            {documentAction ? <ChooseDateMultiAction title={documentAction.title} info={documentAction.docs}
                                                     setDocumentAction={setDocumentAction} func={(date,activeState) => {
                let toDate = date
                if(activeState && activeState.day!=-1){
                    let day=4+activeState.day-1
                    toDate = new Date(`2010-01-0${day}`)
                }
                openOrders.mutate({date: toDate, docs: documentAction.docs});
                setDocumentAction(undefined)
            }}/> : undefined}

            {
                openSelectProductsToExport ? <ProductsToExportDialog
                    open={openSelectProductsToExport != undefined}
                    setOpen={() => setOpenSelectProductsToExport(undefined)}
                    selectedCustomers={openSelectProductsToExport.selectedCustomers}
                    date={date}
                    detailed={openSelectProductsToExport.detailed}
                /> : undefined
            }
            {externalAnchor ? <BaseOrDataSelector disabled={selectedForDuplicate?.some((d: any) => d?.readonly)}
                                                  callPrevDaily={callPrevDaily}
                                                  callPrevOrder={callPrevOrder}
                                                  ids={selectedForDuplicate.map((d: any) => d.id)}
                                                  date={date}
                                                  externalAnchor={externalAnchor}
            /> : undefined}

            {previewOpen && (!(previewOpen?.length && previewOpen?.length> 1)  || previewOpen?.length==1) ? <Dialog fullWidth open={previewOpen} maxWidth="lg"  onBackdropClick={() => {
                setPreviewOpen(undefined);

            }}
                                   sx={{'& .MuiDialog-paper': {p: 0}}}>
                <DocumentPreview setSelectedCustomersSend={setSelectedCustomersSend} document={previewOpen?.length==1 ? previewOpen[0]:previewOpen}
                                 collect={true}/>
            </Dialog> : undefined}
            {
                previewOpen && previewOpen?.length && previewOpen?.length> 1
                    ?
                    <Dialog fullWidth
                            maxWidth='lg'
                            scroll='body' open={true} onBackdropClick={() => {
                        setPreviewOpen(undefined);
                    }}
                            sx={{ '& .MuiDialog-paper': { p: 0 } }}>
                        <Collapse in={true} timeout='auto' unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant='h4' gutterBottom component='div'>
                                    פירוט עבור נהג
                                </Typography>
                                <TableContainer sx={{ maxHeight: 700 }}>
                                    <DocumentsTable data={previewOpen} setSelectedCustomersSendF={setSelectedCustomersSend} />

                                </TableContainer>
                            </Box>
                        </Collapse>
                    </Dialog>
                    : undefined
            }
        </>
    );
};

export default memo(OrdersDataSheet2);
